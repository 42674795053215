<template>
  <div class="relative">
    <div
      class="section1 relative"
    >
      <svg version="1.1" id="logo-img" class="logo-img absolute" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 434 683" style="enable-background:new 0 0 434 683;" xml:space="preserve">
        <symbol id="ball" viewBox="-50.9 -50.9 101.7 101.8">
          <path d="M46.3,0L46.3,0c0-0.2-0.2-0.3-0.3-0.4c-1.4-0.8-2.3-1.5-3-2.5c-0.7-1.1-1.1-2.7-1.4-4.2
            c-0.4-1.6-0.7-3.2-1.3-4.9c-0.6-1.6-1.4-3.2-2.6-4.6c-1.1-1.4-2.6-2.5-4.1-3.3c-0.9-0.5-1.9-0.9-2.9-1.3v-9.7h-9.8
            c-0.3-1-0.7-1.9-1.2-2.8c-0.8-1.5-1.9-2.9-3.3-4.1c-1.4-1.2-3-2-4.6-2.6s-3.3-0.9-4.9-1.3c-1.6-0.4-3.1-0.7-4.2-1.4
            c-1.1-0.7-1.7-1.6-2.5-3c0-0.1-0.1-0.2-0.2-0.3h-0.1l0,0l0,0h-0.1h-0.1l0,0c-0.2,0-0.3,0.2-0.4,0.3c-0.8,1.4-1.5,2.3-2.5,3
            c-1.1,0.7-2.7,1.1-4.2,1.4c-1.6,0.4-3.2,0.7-4.9,1.3c-1.6,0.6-3.2,1.4-4.6,2.6c-1.4,1.1-2.5,2.6-3.3,4.1c-0.5,0.9-0.9,1.8-1.2,2.8
            h-9.8v9.8c-1,0.3-1.9,0.8-2.9,1.3c-1.5,0.8-2.9,1.9-4.1,3.3c-1.2,1.4-2,3-2.6,4.6c-0.6,1.6-0.9,3.3-1.3,4.9
            c-0.4,1.6-0.7,3.1-1.4,4.2c-0.7,1.1-1.6,1.7-3,2.5c-0.1,0-0.2,0.1-0.3,0.2V0l0,0l0,0v0.1v0.1l0,0c0,0.2,0.2,0.3,0.3,0.4
            c1.4,0.8,2.3,1.5,3,2.5c0.7,1.1,1.1,2.7,1.4,4.2c0.4,1.6,0.7,3.2,1.3,4.9c0.6,1.6,1.4,3.2,2.6,4.6c1.1,1.4,2.6,2.5,4.1,3.3
            c0.9,0.5,1.9,0.9,2.9,1.3v9.7h9.7c0.4,1,0.8,2,1.3,3c0.8,1.5,1.9,2.9,3.3,4.1c1.4,1.2,3,2,4.6,2.6s3.3,0.9,4.9,1.3
            c1.6,0.4,3.1,0.7,4.2,1.4c1.1,0.7,1.7,1.6,2.5,3c0,0.1,0.1,0.2,0.2,0.3h0.1l0,0l0,0h0.1h0.1l0,0c0.2,0,0.3-0.2,0.4-0.3
            c0.8-1.4,1.5-2.3,2.5-3c1.1-0.7,2.7-1.1,4.2-1.4c1.6-0.4,3.2-0.7,4.9-1.3c1.6-0.6,3.2-1.4,4.6-2.6c1.4-1.1,2.5-2.6,3.3-4.1
            c0.5-1,1-2,1.3-3h9.7v-9.6c1-0.3,1.9-0.8,2.9-1.3c1.5-0.8,2.9-1.9,4.1-3.3s2-3,2.6-4.6s0.9-3.3,1.3-4.9C42,5.8,42.3,4.3,43,3.2
            c0.7-1.1,1.6-1.7,3-2.5c0.1,0,0.2-0.1,0.3-0.2V0.4l0,0l0,0V0.3C46.3,0.1,46.3,0.1,46.3,0z M29.8-29.9v8.5c-0.3-0.1-0.6-0.2-0.9-0.3
            c-1.7-0.4-3.5-0.5-5.2-0.1c-1,0.2-2,0.6-2.9,1c0.5-0.9,0.8-1.9,1-2.9c0.3-1.7,0.3-3.5-0.1-5.2c-0.1-0.3-0.2-0.7-0.3-1H29.8z
            M-15.5-36.1c1.2-0.9,2.5-1.6,3.9-2.1s3-0.8,4.6-1.2c1.6-0.4,3.3-0.7,5-1.7c0.7-0.4,1.4-1,1.8-1.8c0.4,0.7,1.1,1.4,1.8,1.8
            c1.7,1,3.4,1.3,5,1.7c1.6,0.3,3.1,0.7,4.6,1.2c1.4,0.5,2.8,1.2,3.9,2.1c2.3,1.8,3.8,4.7,4.7,7.6c0.9,2.9,0.3,6.1-1.5,8.5
            c-1.7,2.5-4.6,4.2-7.6,4.4c-1.5,0.1-3-0.2-4.3-1c-1.3-0.7-2.3-1.9-2.6-3.3c-0.6-2.7,1.4-5.7,3.8-5.6c0.3,0,0.6,0.1,0.9,0.2
            c0.1,0,0.4,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.4,0.3,0.7,1,0.8,1.6s-0.1,1.3-0.5,1.7C9.1-21.2,8.5-21,7.8-21l0,0
            c-0.2,0-0.5,0.2-0.5,0.5S7.5-20,7.8-20c0.9,0,1.9-0.1,2.7-0.8c0.8-0.7,1.1-1.7,1.1-2.7s-0.3-2-1-2.8c-0.2-0.2-0.4-0.4-0.7-0.6
            s-0.4-0.2-0.7-0.4c-0.5-0.2-0.9-0.4-1.5-0.4c-1-0.2-2.1,0.1-3,0.6c-0.5,0.3-0.9,0.6-1.2,1c0-0.3-0.1-0.6-0.2-0.8
            C2.9-28,2.3-29,1.7-29.8c-0.3-0.5-0.7-0.8-0.9-1.3c-0.3-0.4-0.5-0.9-0.6-1.4c-0.2-1,0-2,0.8-2.5c0.4-0.3,0.8-0.4,1.3-0.3
            c0.4,0.1,0.8,0.4,1,0.7c0.5,0.7,0,1.9-0.8,2.1c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.6
            s0.3-0.3,0.5-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0,0c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.3-0.1,0.6-0.1,0.9
            c0.1,0.3,0.2,0.6,0.6,0.8C2-32,2.3-31.9,2.6-31.9c1.3-0.2,2-1.8,1.3-2.9c-0.3-0.5-0.8-0.9-1.5-1.1c-0.6-0.1-1.3,0.1-1.7,0.4
            c-0.5,0.3-0.8,0.8-0.9,1.3c-0.2-0.5-0.4-1-0.9-1.3c-0.5-0.4-1.1-0.6-1.7-0.4c-0.6,0.1-1.2,0.5-1.5,1.1c-0.6,1.1,0,2.7,1.3,2.9
            c0.3,0,0.6,0,0.9-0.2c0.3-0.2,0.5-0.5,0.6-0.8c0.1-0.3,0-0.6-0.1-0.9c-0.2-0.3-0.6-0.4-0.8-0.4l0,0c-0.1,0-0.1,0.1-0.1,0.1
            c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0,0.6s-0.2,0.4-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1
            c-0.8-0.2-1.3-1.4-0.8-2.1c0.2-0.4,0.6-0.6,1-0.7c0.4-0.1,0.9,0,1.3,0.3c0.8,0.5,1,1.6,0.8,2.5c-0.1,0.5-0.3,0.9-0.6,1.4
            c-0.3,0.4-0.6,0.8-0.9,1.3c-0.7,1-1.3,2-1.6,3c-0.1,0.3-0.1,0.6-0.2,1c-0.4-0.4-0.8-0.8-1.4-1.1c-0.9-0.5-2-0.7-3-0.6
            c-0.5,0.1-1,0.2-1.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.4c-0.3,0.2-0.5,0.4-0.7,0.6c-0.7,0.9-1,1.9-1,2.8c0,1,0.4,2,1.1,2.7
            c0.8,0.7,1.8,0.9,2.7,0.8c0.2,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5l0,0c-0.7,0-1.4-0.3-1.8-0.7c-0.4-0.4-0.6-1.1-0.5-1.7
            s0.4-1.3,0.8-1.6c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.2c2.3-0.1,4.4,2.8,3.8,5.6
            c-0.3,1.4-1.3,2.6-2.6,3.3s-2.8,1.1-4.3,1c-3-0.2-5.9-1.9-7.6-4.4c-1.8-2.5-2.3-5.7-1.5-8.5C-19.4-31.4-17.9-34.3-15.5-36.1z
            M21.8,0.2L21.8,0.2c0,0.7,0.2,1.4,0.5,2c-1-0.1-2.1,0-3.1,0.3c-1.7,0.6-3,1.8-3.9,3.2c-0.4-0.4-0.8-0.8-1.4-1c-1-0.4-2-0.3-3,0.1
            C11.3,5.8,12,6.6,13,7c0.5,0.2,1,0.3,1.6,0.3c-0.5,1.2-0.7,2.6-0.6,3.9c0.1,1.6,0.6,3.2,1.3,4.6c-1.4-0.7-3-1.2-4.6-1.3
            c-1.3-0.1-2.6,0.1-3.9,0.6c0-0.5-0.1-1.1-0.3-1.6c-0.4-1-1.2-1.7-2.2-2.1c-0.4,1-0.5,2-0.1,3c0.2,0.6,0.6,1,1,1.4
            C3.8,16.7,2.6,18,2,19.7c-0.4,1-0.5,2.1-0.3,3.1c-0.6-0.4-1.4-0.5-2.1-0.6l0,0l0,0c-0.6,0-1.3,0.2-1.9,0.5c0.1-1,0-2.1-0.3-3.1
            c-0.6-1.7-1.8-3-3.2-3.9c0.4-0.4,0.8-0.8,1-1.4c0.4-1,0.3-2-0.1-3c-1,0.4-1.8,1.1-2.2,2.1c-0.2,0.5-0.3,1-0.3,1.6
            c-1.2-0.5-2.6-0.7-3.9-0.6c-1.6,0.1-3.2,0.6-4.6,1.3c0.7-1.4,1.2-3,1.3-4.6c0.1-1.3-0.1-2.6-0.6-3.9c0.5,0,1.1-0.1,1.6-0.3
            c1-0.4,1.7-1.2,2.1-2.2c-1-0.4-2-0.5-3-0.1c-0.6,0.2-1,0.6-1.4,1c-0.9-1.4-2.2-2.6-3.9-3.2c-1-0.4-2.1-0.5-3.1-0.3
            c0.4-0.6,0.5-1.4,0.6-2.1l0,0l0,0c0-0.6-0.2-1.3-0.5-1.9c1,0.1,2.1,0,3.1-0.3c1.7-0.6,3-1.8,3.9-3.2c0.4,0.4,0.8,0.8,1.4,1
            c1,0.4,2,0.3,3-0.1c-0.4-1-1.1-1.8-2.1-2.2C-14-6.9-14.5-7-15-7c0.5-1.2,0.7-2.6,0.6-3.9c-0.1-1.6-0.6-3.2-1.3-4.6
            c1.4,0.7,3,1.2,4.6,1.3c1.3,0.1,2.6-0.1,3.9-0.6c0,0.5,0.1,1.1,0.3,1.6c0.4,1,1.2,1.7,2.2,2.1c0.4-1,0.5-2,0.1-3
            c-0.2-0.6-0.6-1-1-1.4c1.4-0.9,2.6-2.2,3.2-3.9c0.4-1,0.5-2.1,0.3-3.1c0.6,0.4,1.4,0.5,2.1,0.6l0,0l0,0c0.6,0,1.3-0.2,1.9-0.5
            c-0.1,1,0,2.1,0.3,3.1c0.6,1.7,1.8,3,3.2,3.9c-0.4,0.4-0.8,0.8-1,1.4c-0.4,1-0.3,2,0.1,3c1-0.4,1.8-1.1,2.2-2.1
            c0.2-0.5,0.3-1,0.3-1.6c1.2,0.5,2.6,0.7,3.9,0.6c1.6-0.1,3.2-0.6,4.6-1.3c-0.7,1.4-1.2,3-1.3,4.6c-0.1,1.3,0.1,2.6,0.6,3.9
            c-0.5,0-1.1,0.1-1.6,0.3c-1,0.4-1.7,1.2-2.1,2.2c1,0.4,2,0.5,3,0.1c0.6-0.2,1-0.6,1.4-1c0.9,1.4,2.2,2.6,3.9,3.2
            c1,0.4,2.1,0.5,3.1,0.3C22-1.2,22-0.5,21.8,0.2L21.8,0.2z M2.3,27c-0.2,1-0.6,1.9-1.1,2.8c-0.5,0.8-1.3,1.8-1.5,2.8l0,0l0,0
            c-0.3-1.1-1-2-1.5-2.8c-0.5-0.9-0.9-1.9-1.1-2.8C-3,26-3,25-2.5,24.3c0.4-0.7,1.4-1.1,2.3-1.2c1,0.1,1.9,0.5,2.3,1.2
            C2.5,25,2.5,26,2.3,27z M-2.8-26.6c0.2-1,0.6-1.9,1.1-2.8c0.5-0.8,1.3-1.8,1.5-2.8l0,0l0,0c0.3,1.1,1,2,1.5,2.8
            c0.5,0.9,0.9,1.9,1.1,2.8c0.2,1,0.2,2-0.3,2.7c-0.4,0.7-1.4,1.1-2.3,1.2c-1-0.1-1.9-0.5-2.3-1.2C-3-24.7-3-25.7-2.8-26.6z
            M-30.2-29.9h8.4c-0.1,0.3-0.2,0.7-0.3,1c-0.4,1.7-0.5,3.5-0.1,5.2c0.2,1,0.6,2,1,2.9c-0.9-0.5-1.9-0.8-2.9-1
            c-1.7-0.3-3.5-0.3-5.2,0.1c-0.3,0.1-0.6,0.2-0.9,0.3C-30.1-21.4-30.1-29.9-30.2-29.9z M-36.5,15.5c-0.9-1.2-1.6-2.5-2.1-3.9
            s-0.8-3-1.2-4.6c-0.4-1.6-0.7-3.3-1.7-5c-0.4-0.7-1-1.4-1.8-1.8c0.8-0.5,1.4-1.1,1.8-1.8c1-1.7,1.3-3.4,1.7-5
            c0.3-1.6,0.7-3.1,1.2-4.6c0.5-1.4,1.2-2.8,2.1-3.9c1.8-2.3,4.7-3.8,7.6-4.7c2.9-0.9,6.1-0.3,8.5,1.5c2.5,1.7,4.2,4.6,4.4,7.6
            c0.1,1.5-0.2,3-1,4.3c-0.7,1.3-1.9,2.3-3.3,2.6C-23-3.2-26-5.2-25.9-7.6c0-0.3,0.1-0.6,0.2-0.9c0-0.1,0.1-0.4,0.1-0.4
            c0-0.1,0.1-0.2,0.2-0.3c0.3-0.4,1-0.7,1.6-0.8s1.3,0.1,1.7,0.5c0.5,0.4,0.7,1.1,0.7,1.8l0,0c0,0.2,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5
            c0-0.9-0.1-1.9-0.8-2.7c-0.7-0.8-1.7-1.1-2.7-1.1s-2,0.3-2.8,1c-0.2,0.2-0.4,0.4-0.6,0.7s-0.2,0.4-0.4,0.7C-27.8-8.6-28-8.2-28-7.6
            c-0.2,1,0.1,2.1,0.6,3c0.3,0.5,0.6,0.9,1,1.2c-0.3,0-0.6,0.1-0.8,0.2c-1.1,0.3-2.1,0.9-2.9,1.5c-0.5,0.3-0.8,0.7-1.3,0.9
            c-0.4,0.3-0.9,0.5-1.4,0.6c-1,0.2-2,0-2.5-0.8c-0.3-0.4-0.4-0.8-0.3-1.3c0.1-0.4,0.4-0.8,0.7-1c0.7-0.5,1.9,0,2.1,0.8
            c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0s-0.3-0.3-0.3-0.5c0-0.1-0.1-0.1-0.1-0.1
            c-0.1,0-0.1,0.1-0.2,0.1l0,0c0,0.3,0.1,0.6,0.4,0.8s0.6,0.2,0.9,0.1c0.3-0.1,0.6-0.2,0.8-0.6c0.1-0.3,0.2-0.6,0.2-0.9
            c-0.2-1.3-1.8-2-2.9-1.3c-0.5,0.3-0.9,0.8-1.1,1.5c-0.1,0.6,0.1,1.3,0.4,1.7c0.3,0.5,0.8,0.8,1.3,0.9c-0.5,0.2-1,0.4-1.3,0.9
            c-0.4,0.5-0.6,1.1-0.4,1.7c0.1,0.6,0.5,1.2,1.1,1.5c1.1,0.6,2.7,0,2.9-1.3c0-0.3,0-0.6-0.2-0.9c-0.2-0.3-0.5-0.5-0.8-0.6
            c-0.3-0.1-0.6,0-0.9,0.1c-0.3,0.2-0.4,0.6-0.4,0.8l0,0c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.2,0.1-0.4,0.3-0.5
            c0.2-0.1,0.4-0.1,0.6,0S-33,2.1-33,2.3c0.1,0.2,0.1,0.4,0.1,0.6c-0.2,0.8-1.4,1.3-2.1,0.8c-0.4-0.2-0.6-0.6-0.7-1
            c-0.1-0.4,0-0.9,0.3-1.3c0.5-0.8,1.6-1,2.5-0.8c0.5,0.1,0.9,0.3,1.4,0.6c0.4,0.3,0.8,0.6,1.3,0.9c0.9,0.6,1.8,1.2,2.9,1.5
            c0.3,0.1,0.6,0.1,1,0.2c-0.4,0.4-0.8,0.8-1.1,1.4c-0.5,0.9-0.7,2-0.6,3c0.1,0.5,0.2,1,0.4,1.5c0.1,0.2,0.2,0.4,0.4,0.7
            c0.2,0.3,0.4,0.5,0.6,0.7c0.9,0.7,1.9,1,2.8,1c1,0,2-0.4,2.7-1.1c0.7-0.8,0.9-1.8,0.8-2.7c0-0.2-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5
            l0,0c0,0.7-0.3,1.4-0.7,1.8c-0.4,0.4-1.1,0.6-1.7,0.5s-1.3-0.4-1.6-0.8c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.3-0.1-0.4
            c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-2.3,2.8-4.4,5.6-3.8c1.4,0.3,2.6,1.3,3.3,2.6s1.1,2.8,1,4.3c-0.2,3-1.9,5.9-4.4,7.6
            c-2.5,1.8-5.7,2.3-8.5,1.5C-31.8,19.3-34.7,17.8-36.5,15.5z M-23.1,0.1c-0.1,1-0.5,1.9-1.2,2.3C-25,2.9-26,2.9-27,2.7
            s-1.9-0.6-2.8-1.1c-0.8-0.5-1.8-1.3-2.8-1.5l0,0l0,0c1.1-0.3,2-1,2.8-1.5c0.9-0.5,1.9-0.9,2.8-1.1c1-0.2,2-0.2,2.7,0.3
            C-23.6-1.8-23.2-0.9-23.1,0.1z M-30.2,30.1v-8.3c0.3,0.1,0.6,0.2,0.9,0.3c1.7,0.4,3.5,0.5,5.2,0.1c1-0.2,2-0.6,2.9-1
            c-0.5,0.9-0.8,1.9-1,2.9c-0.3,1.7-0.3,3.5,0.1,5.2c0.1,0.3,0.1,0.5,0.2,0.8H-30.2z M15.1,36.4c-1.2,0.9-2.5,1.6-3.9,2.1
            s-3,0.8-4.6,1.2c-1.6,0.4-3.3,0.7-5,1.7c-0.7,0.4-1.4,1-1.8,1.8c-0.4-0.7-1.1-1.4-1.8-1.8c-1.7-1-3.4-1.3-5-1.7
            c-1.6-0.3-3.1-0.7-4.6-1.2c-1.4-0.5-2.8-1.2-3.9-2.1c-2.3-1.8-3.8-4.7-4.7-7.6c-0.9-2.9-0.3-6.1,1.5-8.5c1.7-2.5,4.6-4.2,7.6-4.4
            c1.5-0.1,3,0.2,4.3,1c1.3,0.7,2.3,1.9,2.6,3.3c0.6,2.7-1.4,5.7-3.8,5.6c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0-0.4-0.1-0.4-0.1
            c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.3-0.7-1-0.8-1.6s0.1-1.3,0.5-1.7c0.4-0.5,1.1-0.7,1.8-0.7l0,0c0.2,0,0.5-0.2,0.5-0.5
            s-0.2-0.5-0.5-0.5c-0.9,0-1.9,0.1-2.7,0.8c-0.8,0.7-1.1,1.7-1.1,2.7s0.3,2,1,2.8c0.2,0.2,0.4,0.4,0.7,0.6s0.4,0.2,0.7,0.4
            C-9,27.8-8.6,28-8,28c1,0.2,2.1-0.1,3-0.6c0.5-0.3,0.9-0.6,1.2-1c0,0.3,0.1,0.6,0.2,0.8c0.3,1.1,0.9,2.1,1.5,2.9
            c0.3,0.5,0.7,0.8,0.9,1.3c0.3,0.4,0.5,0.9,0.6,1.4c0.2,1,0,2-0.8,2.5c-0.4,0.3-0.8,0.4-1.3,0.3c-0.4-0.1-0.8-0.4-1-0.7
            c-0.5-0.7,0-1.9,0.8-2.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0,0.6s-0.3,0.3-0.5,0.3
            c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.2l0,0c0.3,0,0.6-0.1,0.8-0.4s0.2-0.6,0.1-0.9c-0.1-0.3-0.2-0.6-0.6-0.8
            c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3,0.2-2,1.8-1.3,2.9c0.3,0.5,0.8,0.9,1.5,1.1c0.6,0.1,1.3-0.1,1.7-0.4c0.5-0.3,0.8-0.8,0.9-1.3
            c0.2,0.5,0.4,1,0.9,1.3c0.5,0.4,1.1,0.6,1.7,0.4C3,36.1,3.7,35.7,4,35.1c0.6-1.1,0-2.7-1.3-2.9c-0.3,0-0.6,0-0.9,0.2
            c-0.3,0.2-0.5,0.5-0.6,0.8s0,0.6,0.1,0.9c0.2,0.3,0.6,0.4,0.8,0.4l0,0c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
            c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4,0-0.6S1.8,33,2,33c0.2-0.1,0.4-0.1,0.6-0.1c0.8,0.2,1.3,1.4,0.8,2.1
            c-0.2,0.4-0.6,0.6-1,0.7s-0.9,0-1.3-0.3c-0.8-0.5-1-1.6-0.8-2.5c0.1-0.5,0.3-0.9,0.6-1.4c0.3-0.4,0.6-0.8,0.9-1.3
            c0.6-0.9,1.2-1.8,1.5-2.9c0.1-0.3,0.1-0.6,0.2-1c0.4,0.4,0.8,0.8,1.4,1.1c0.9,0.5,2,0.7,3,0.6c0.5-0.1,1-0.2,1.5-0.4
            c0.2-0.1,0.4-0.2,0.7-0.4c0.3-0.2,0.5-0.4,0.7-0.6c0.7-0.9,1-1.9,1-2.8c0-1-0.4-2-1.1-2.7c-0.8-0.7-1.8-0.9-2.7-0.8
            c-0.2,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5l0,0c0.7,0,1.4,0.3,1.8,0.7c0.4,0.4,0.6,1.1,0.5,1.7S9.9,25,9.5,25.3
            c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1-0.6,0.2-0.9,0.2c-2.3,0.1-4.4-2.8-3.8-5.6c0.3-1.4,1.3-2.6,2.6-3.3
            c1.3-0.7,2.8-1.1,4.3-1c3,0.2,5.9,1.9,7.6,4.4c1.8,2.5,2.3,5.7,1.5,8.5C19,31.7,17.5,34.6,15.1,36.4z M29.8,30.1h-8.4
            c0.1-0.3,0.2-0.5,0.2-0.8c0.4-1.7,0.5-3.5,0.1-5.2c-0.2-1-0.6-2-1-2.9c0.9,0.5,1.9,0.8,2.9,1c1.7,0.3,3.5,0.3,5.2-0.1
            c0.3-0.1,0.6-0.2,0.9-0.3v8.3H29.8z M41,2c-1,1.7-1.3,3.4-1.7,5c-0.3,1.6-0.7,3.1-1.2,4.6c-0.5,1.4-1.2,2.8-2.1,3.9
            c-1.8,2.3-4.7,3.8-7.6,4.7c-2.9,0.9-6.1,0.3-8.5-1.5c-2.5-1.7-4.2-4.6-4.4-7.6c-0.1-1.5,0.2-3,1-4.3c0.7-1.3,1.9-2.3,3.3-2.6
            c2.7-0.6,5.7,1.4,5.6,3.8c0,0.3-0.1,0.6-0.2,0.9c0,0.1-0.1,0.4-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-1,0.7-1.6,0.8
            s-1.3-0.1-1.7-0.5C21.2,9.5,21,8.8,21,8.1l0,0c0-0.2-0.2-0.5-0.5-0.5S20,7.8,20,8.1c0,0.9,0.1,1.9,0.8,2.7c0.7,0.8,1.7,1.1,2.7,1.1
            s2-0.3,2.8-1c0.2-0.2,0.4-0.4,0.6-0.7s0.2-0.4,0.4-0.7C27.5,9,27.7,8.6,27.7,8c0.2-1-0.1-2.1-0.6-3c-0.3-0.5-0.6-0.9-1-1.2
            c0.3,0,0.6-0.1,0.8-0.2C28,3.3,29,2.7,29.8,2.1c0.5-0.3,0.8-0.7,1.3-0.9c0.4-0.3,0.9-0.5,1.4-0.6c1-0.2,2,0,2.5,0.8
            c0.3,0.4,0.4,0.8,0.3,1.3c-0.1,0.4-0.4,0.8-0.7,1c-0.7,0.5-1.9,0-2.1-0.8c0-0.2,0-0.4,0.1-0.6c0-0.2,0.2-0.3,0.4-0.4
            c0.2-0.1,0.4-0.1,0.6,0s0.3,0.3,0.3,0.5c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.2-0.1l0,0c0-0.3-0.1-0.6-0.4-0.8s-0.6-0.2-0.9-0.1
            c-0.3,0.1-0.6,0.2-0.8,0.6C32,2.4,31.9,2.7,31.9,3c0.2,1.3,1.8,2,2.9,1.3c0.5-0.3,0.9-0.8,1.1-1.5c0.1-0.6-0.1-1.3-0.4-1.7
            c-0.3-0.5-0.8-0.8-1.3-0.9c0.5-0.2,1-0.4,1.3-0.9c0.4-0.5,0.6-1.1,0.4-1.7c-0.1-0.6-0.5-1.2-1.1-1.5c-1.1-0.6-2.7,0-2.9,1.3
            c0,0.3,0,0.6,0.2,0.9c0.2,0.3,0.5,0.5,0.8,0.6s0.6,0,0.9-0.1c0.3-0.2,0.4-0.6,0.4-0.8l0,0c0-0.1-0.1-0.1-0.1-0.1C34-2.1,34-2,34-2
            c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.1-0.6,0s-0.4-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0.2-0.8,1.4-1.3,2.1-0.8
            c0.4,0.2,0.6,0.6,0.7,1s0,0.9-0.3,1.3c-0.5,0.8-1.6,1-2.5,0.8c-0.5-0.1-0.9-0.3-1.4-0.6c-0.4-0.3-0.8-0.6-1.3-0.9
            C29-2.3,28.1-2.9,27-3.2c-0.3-0.1-0.6-0.1-1-0.2c0.4-0.4,0.8-0.8,1.1-1.4c0.5-0.9,0.7-2,0.6-3c-0.1-0.5-0.2-1-0.4-1.5
            c-0.1-0.2-0.2-0.4-0.4-0.7c-0.2-0.3-0.4-0.5-0.6-0.7c-0.9-0.7-1.9-1-2.8-1c-1,0-2,0.4-2.7,1.1c-0.7,0.8-0.9,1.8-0.8,2.7
            c0,0.2,0.2,0.5,0.5,0.5S21-7.6,21-7.9l0,0c0-0.7,0.3-1.4,0.7-1.8c0.4-0.4,1.1-0.6,1.7-0.5s1.3,0.4,1.6,0.8c0.1,0.1,0.1,0.2,0.2,0.3
            c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.6,0.2,0.9c0.1,2.3-2.8,4.4-5.6,3.8c-1.4-0.3-2.6-1.3-3.3-2.6c-0.7-1.3-1.1-2.8-1-4.3
            c0.2-3,1.9-5.9,4.4-7.6c2.5-1.8,5.7-2.3,8.5-1.5c3,0.9,5.8,2.4,7.6,4.7c0.9,1.2,1.6,2.5,2.1,3.9s0.8,3,1.2,4.6
            c0.4,1.6,0.7,3.3,1.7,5c0.4,0.7,1,1.4,1.8,1.8C42,0.6,41.5,1.2,41,2z M22.6,0.2c0.1-1,0.5-1.9,1.2-2.3c0.7-0.5,1.7-0.5,2.7-0.3
            s1.9,0.6,2.8,1.1c0.8,0.5,1.8,1.3,2.8,1.5l0,0l0,0c-1.1,0.3-2,1-2.8,1.5c-0.9,0.5-1.9,0.9-2.8,1.1c-1,0.2-2,0.2-2.7-0.3
            C23.1,2.1,22.8,1.2,22.6,0.2z M10.2,4.5C9.1,4.6,7.7,6.9,5.7,6.1c0.8,2-1.6,3.4-1.6,4.5c-0.9-0.8-3.5-0.1-4.3-2
            c-0.8,1.9-3.5,1.3-4.3,2C-4.5,9.5-6.9,8.1-6,6.1c-2,0.8-3.4-1.6-4.5-1.6c0.8-0.9,0.1-3.5,2-4.3c-1.9-0.8-1.3-3.5-2-4.3
            C-9.5-4.2-8-6.5-6-5.7c-0.8-2,1.6-3.4,1.6-4.5c0.9,0.8,3.5,0.1,4.3,2c0.8-1.9,3.5-1.3,4.3-2c0.1,1.1,2.4,2.5,1.6,4.5
            c2-0.8,3.4,1.6,4.5,1.6c-0.8,0.9-0.1,3.5-2,4.3C10.1,1,9.5,3.6,10.2,4.5z M37-24.5V-37H24.5C20.6-45.1,11.1-50.9,0-50.9
            S-20.7-45.1-24.5-37H-37v12.5C-45.1-20.7-50.9-11.2-50.9,0s5.8,20.6,13.9,24.5V37h12.5c3.8,8.1,13.3,13.9,24.5,13.9
            S20.6,45.1,24.5,37H37V24.4C45,20.6,50.9,11.1,50.9-0.1S45.1-20.7,37-24.5z M36.1,22.6L35,23.1v1.3v10.5H24.5h-1.3l-0.5,1.2
            C19,43.7,9.9,48.8,0,48.8s-19-5.1-22.6-12.7l-0.6-1.2h-1.3H-35V24.4v-1.3l-1.2-0.6C-43.8,19-48.9,9.9-48.9,0s5.1-19,12.7-22.6
            l1.2-0.6v-1.3V-35h10.5h1.3l0.6-1.2C-19.1-43.8-10-48.9,0-48.9s19,5.1,22.6,12.7l0.6,1.2h1.3H35v10.5v1.3l1.2,0.6
            C43.8-19,48.9-9.9,48.9,0S43.7,19,36.1,22.6z M-46-46c0.5,0.5,5,2.7,6.4,4.1s1.7,2.8,1.1,3.4c-0.6,0.6-2,0.3-3.4-1.1
            C-43.2-40.9-45.5-45.5-46-46z M-48.1-47.7c8.4,9.6-1.2,15.5-0.9,11.2C-48.8-40.1-46.5-39.6-48.1-47.7z M-47.7-48.1
            c8.1,1.6,7.6-0.7,11.2-0.9C-32.2-49.3-38.1-39.7-47.7-48.1z M46,45.9c-0.5-0.5-5-2.7-6.4-4.1s-1.7-2.8-1.1-3.4s2-0.3,3.4,1.1
            C43.2,40.8,45.5,45.4,46,45.9z M48,47.6C39.6,38,49.2,32.1,49,36.4C48.8,40,46.5,39.6,48,47.6z M47.6,48c-8.1-1.6-7.6,0.7-11.2,0.9
            C32.2,49.2,38,39.6,47.6,48z M46-46c-0.5,0.5-2.7,5-4.1,6.4c-1.4,1.4-2.8,1.7-3.4,1.1s-0.3-2,1.1-3.4C40.8-43.2,45.5-45.5,46-46z
            M47.6-48.1C38-39.7,32.1-49.3,36.5-49C40-48.8,39.6-46.5,47.6-48.1z M48-47.7c-1.6,8.1,0.7,7.6,0.9,11.2
            C49.2-32.2,39.6-38.1,48-47.7z M-46,45.9c0.5-0.5,2.7-5,4.1-6.4s2.8-1.7,3.4-1.1c0.6,0.6,0.3,2-1.1,3.4
            C-40.9,43.2-45.5,45.4-46,45.9z M-47.7,48c9.6-8.4,15.5,1.2,11.2,0.9C-40.1,48.8-39.6,46.4-47.7,48z M-48.1,47.6
            c1.6-8.1-0.7-7.6-0.9-11.2C-49.3,32.2-39.7,38-48.1,47.6z"/>
        </symbol>
        <defs>
          <path id="SVGID_1_" d="M3.1,682.6V71.9l0,0c0-0.1-0.1-0.2,0-0.2C84.5,36.4,95.7,0.4,210,0.4h14c114.3,0,125.5,36,206.9,71.3
            c0.1,0,0,0.1,0,0.2l0,0v610.7L3.1,682.6L3.1,682.6z M380.7,75.4H275.9v104.8h104.8V75.4z M157.2,300.2H52.4V405h104.8L157.2,300.2
            L157.2,300.2z M377.7,524.5H272.9v104.8h104.8V524.5z"/>
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
        </clipPath>
        <g class="st3">
          <g class="st0">
            <use class="ro1" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="-76.8" />
            <use class="ro2" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="27.5"/>
            <use class="ro3" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="131.6"/>
            <use class="ro4" xlink:href="#ball" width="101.7" height="101.7" x="108" y="-76.8"/>
            <use class="ro5"  xlink:href="#ball" width="101.7" height="101.7" x="108" y="27.5"/>
            <use class="ro6"  xlink:href="#ball" width="101.7" height="101.7" x="108" y="131.6"/>
          </g>
          <g class="st1">
            <use class="ro7" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="-76.8" />
            <use class="ro8" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="27.5"/>
            <use class="ro9" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="131.6"/>
            <use class="ro10" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="-76.8"/>
            <use class="ro11" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="27.5"/>
            <use class="ro12" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="131.6"/>
          </g>
          <g class="st1">
            <use class="ro13" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="250.8" />
            <use class="ro14" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="355.1"/>
            <use class="ro15" xlink:href="#ball" width="101.7" height="101.7" x="108" y="250.8"/>
            <use class="ro16" xlink:href="#ball" width="101.7" height="101.7" x="108" y="355.1"/>
          </g>
          <g class="st0">
            <use class="ro17" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="250.8" />
            <use class="ro18" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="355.1"/>
            <use class="ro19" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="250.8"/>
            <use class="ro20" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="355.1"/>
          </g>
          <g class="st0">
            <use class="ro21" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="473.6" />
            <use class="ro22" xlink:href="#ball" width="101.7" height="101.7" x="3.9" y="577.9"/>
            <use class="ro23" xlink:href="#ball" width="101.7" height="101.7" x="108" y="473.6"/>
            <use class="ro24" xlink:href="#ball" width="101.7" height="101.7" x="108" y="577.9"/>
          </g>
          <g class="st1">
            <use class="ro25" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="473.6" />
            <use class="ro26" xlink:href="#ball" width="101.7" height="101.7" x="225.4" y="577.9"/>
            <use class="ro27" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="473.6"/>
            <use class="ro28" xlink:href="#ball" width="101.7" height="101.7" x="329.5" y="577.9"/>
          </g>
        </g>
      </svg>
      <svg version="1.1" id="logo1" class="logo1 absolute" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve">
        <g>
          <defs>
            <path id="logo11" d="M123.8,105.2l-6.2-0.8c-3.4,0.1-4,2.7-5,4.8c-1.2,0.1-2.4,0.1-3.6,0.2c-0.2,3.3-2.5,4.2-4.9,5.1
              c2,1.2,3.9,2,4.9,4.6c0.6,1.5,4,1.9,6.6,3c1.6-2,3.1-3.8,4.2-5.2c2.1,0,3.9,0.5,5.1-0.1c3.3-1.7,6.7-1.7,10.1-1.3
              c3.8,0.4,7.2-1.4,10.8-1.6c4.1-0.2,8.1,0,12.1,0c1.9-1.4,3.7-2.7,5.6-4c-0.7-0.7-1.2-1.2-2-2c-11.1,0-22.5,0-33.9,0
              c0.4-0.5,0.8-1,1.2-1.5C127.3,106.1,126,105.7,123.8,105.2z M173.4,148.9c3,3,7.2,1.5,11.3,2.8c-1,4.4-0.5,8.9-2.2,13.6
              c-1.6,4.4-1.7,9.3-2.5,14.3c-2.4,1.9-0.6,7.2-1.1,10.5c-0.5,3.8-1.6,7.7-2.2,11.6c-0.3,2.3-3.5,11.9-3.4,14.9
              c0,3.9-15,10.2-14.2,2.3c-1.5-0.5,3.3-5.7,0.7-6.6c-3.1,1.1-39.5,3.3-40.7,8.5l-6.5-0.4c-4.3,2.1-9.1,0.1-12,4.2
              c-1.6-1-2.8-1.7-4.5-2.8c-1.4-2.5-1.6-5.8-1-9.3c0.6-4.3,1-8.6,1.3-12.9c0.6-7.6,3.6-14.5,6.3-21.4c0.6-1.6,0.7-3.3,1.1-4.9
              c-3.5-2-6-4.5-7.5-8.9c2.7,0.1,4.9,0.2,7.2,0.3c2.9-1.8,5.8-4,9.8-3.6c2.1,0.2,4.3-0.3,6.3-1c1.3-0.5,6.8,0.3,11.9-3
              c3.6-2.3,6.9-2.4,10.2-2.4c9.9,0.1,19.1-4.5,21-5.3C166.1,148.9,169.9,151.3,173.4,148.9z M161.7,180.9c0.2-2.4,1.4-5.2,0.5-7.3
              c-1.6-3.8-0.2-7.4-0.3-11c0-0.4-0.4-0.8-0.6-1.2c-1-0.1-2.1-0.4-3-0.2c-0.7,0.1-32.9,6.8-33.9,7.6l-5.7,0.4
              c-1.9,1.6-3.8,3.3-5.6,4.8c0,5.8,0.1,10.9,0.1,16.1l-5.3,17.6c1.1,0.9,2.2,1.8,3.3,2.6c1.1-0.9,3.2,0.2,4.2-0.6
              c0,0,14-0.7,15.7-0.1c1.7,0.6,12.7-1.3,12.7-1.3l4.6-2.5l4.8-1c3.2,1,5.8,1.4,7.5-0.9c0.5-4.6,0.9-8.8,1.4-14.3L161.7,180.9z
              M72.7,67.1c-3.8,0.6-7.9,1.1-11.9,1.8c-1.4,0.3-2.7,0.6-4.1,1.1c0.2-2.3,0.5-4.6,1-6.9c1.3-5.9,0.7-12.2,1.5-18.4
              c1.1-9.5,0.3-19.3,0.3-29c0-1.6-0.5-2.7-1.5-4c-0.8-1-0.6-2.9-1.3-4c-0.7-1-1.4-2.4-3.1-2.5c-0.6,8-1.2,15.6-1.8,23.3
              c-0.1,0.7,0,1.7-0.4,2.1c-1.9,1.7-0.7,3.8-1.1,5.7c-0.4,2.1-1.3,4.1-1.4,6.2c-0.1,1.8,1.4,3.5,0,5.7c-1.1,1.7-1.4,4.1-1.6,6.2
              c-0.1,1.2,0.7,2.5,0.9,3.4c-3.6,5.2-4.5,11-5.4,16.7c-2,0.7-3.9,1.4-5.8,2.2c-4.3,1.9-8.8,2.4-14.7,2c2.6,2.3,4.2,3.7,5.4,4.8
              c4.9-1.1,9.5-2.1,14.1-3.2c-0.2,0.7-0.3,1.4-0.5,2.1c0.5,0.6,0.8,1.1,0.7,0.9c-1,2.9-2.4,5.2-2.3,7.4C40,98,37,104.9,38,112.2
              c0.1,0.7,0.3,1.8-0.1,2.2c-2.7,2.6-0.2,6.1-1.6,8.5c-1.6,2.9-1.2,5.7-1.4,8.6c-0.2,3.1,0.5,6.2-1.3,9.4c-0.5,1-0.7,2.4-0.6,3.8
              c-1.5,0.4-3,1.5-4.5,1.4c-5.3-0.4-9.8,1.9-14.4,3.8c-0.7,2.7-1.3,5.2-1.9,7.5c1.1,1.1,1.8,1.8,2.6,2.6c2.4,0,5.8,1,7.3-0.2
              c3.4-2.9,7.1-0.4,10.7-2.1c0.7-0.3,1.4-0.5,2.1-0.7c0.1,8.4,0.1,16.8,0,25.2c0,1.7-0.8,3.3-0.8,5s1.4,4.2,0.7,5.1
              c-2.7,3-1.7,6.6-1.1,9.4c0.8,3.3-1.8,6.7,0.2,9.2c1.7,2.1-1.9,3.3,0,4.9c2.2,1.8,1,4.3,1,6.4c-0.1,4.3,1.4,7.4,4.8,8.7
              c4.3-1.2,8.1-2.2,12.1-3.3c4.6-6.3,3.7-8.9,1.7-14.4c1.6-4.5,3-8.7,4.5-12.9c-1.2-1.3-2.3-2.5-3.3-3.7c2.8-5.3,2.2-10.5,1-15.5
              c-1.2-5.2-1.4-10.3,0-15.3c0.7-2.2,0.6-4,0-6.3c-1.4-5-1.8-10.2,0.7-15.2c-0.7-1.7-1.4-2.7-1.5-3.8c-0.2-2.3-0.1-4.6-0.1-6.9
              c0-2.3,0.9-5.4-0.2-6.7c-2.3-2.5,1-3.7,0.4-5.8c-0.7-2.3,0.7-5.6-0.4-7.5c-1.6-2.6-0.1-4.7,0.4-6.4c1.5-4.9,1.1-9.8,1.4-14.7
              c0.3-5.7-0.1-11.4,0-17.1c0.3-0.1,0.7-0.2,1.1-0.4c4.7-1.5,9.3-1.1,13.9-1.4c1.3-1.3,2.5-2.5,3.8-3.8
              C74.5,68.9,73.8,68.2,72.7,67.1z M215.2,90.6c0.6-1.6-1.9,0-1.6-1.7c0.4-2-2.3-1.7-2.9-3.3c-0.5-1.3-1.2-3.1-3.7-2.6
              c-0.7,0.1-0.7-1.5-1.3-2.1c-0.5-0.6-1.5-0.7-2.1-1.3c-3.1-3.2-6.2-6.4-9.2-9.7c-0.6-0.7-1.3-1.1-1.3-2.5c0-1.3-1-2.6-2.2-2.7
              c-2.1-0.1-2.7-1.5-3.8-2.5c-1.1-0.9-1.8-2.1-3.6-2.2c-1.6-0.1-1.3-2.7-3.7-2.1c-0.3,0.1-0.6-0.4-0.8-0.7c-1.8-3.1-5.8-3.8-8-6.4
              c-0.7-0.8-2.3-0.8-3.8-1.3c0,0.2,0-0.2,0.1-0.7c-3.9-1.6-6.5-4.9-12.1-4.5c-0.1-0.5-0.1-1.3-0.6-1.7c-0.8-0.6-1.7-1.3-2.9-1.2
              c-1.5,0-1.2-1.4-1.8-2c-0.8-0.6-2-0.9-2.9-1.3c-0.8-0.4-0.9-1.8-2.5-1.3c-0.4,0.1-0.7-0.3-0.9-0.5c-2.4-1.9-4.8-3.9-7.3-5.9
              c0.5-1.7,0.9-3.5,1.4-5.2c0.3-1.2-0.2-2.6-0.4-4.6C130.6,27,128.2,36,121,41.3c-0.6,1.9-1.2,4.1-1.6,5.3c-4,3-7.5,4.9-9.7,8.8
              c-1.9,1-4.2,2.1-6.4,3.2c0,4.5-6.2,5.3-5.9,10.2c5,0.3,6-4.5,9.1-5.7c-0.2-1.3-0.3-2-0.4-2.3c1.5-0.3,2.9-0.6,4.3-0.9
              c0.1-0.5,0.2-1.1,0.4-1.6c0.3,0.3,0.7,0.6,1,0.9c0.8-0.4,1.7-0.8,2.4-1.2c-0.2-1.4-0.3-2.3-0.6-3.8c1.9,3.2,4.4,2.1,6.4,2.3
              c0-2.1,0-4.2-0.1-7.1c1.5,2,2.3,3.1,4.1,5.5c-0.8-3-1.2-4.5-1.9-6.9c1.4,1.4,2.1,2,2.5,2.4c2.6-2.9,5.1-5.9,8.6-9.9
              c0.3-1.3,0.7-2.7,1.2-4.2c0,0.1,0.1,0.1,0.1,0.2c3.2,3.3,5.9,7.1,9.7,9.7c2.4,1.7,4.2,4.2,6.9,5.5c4.5,2.1,6.8,6.2,10.3,9.3
              c1.8,1.5,3.1,3.5,5.6,4.3c0.9,0.3,2.4,0.2,2.4,2c0,1.3,1.9,1.6,2.2,2.8c0.3,1.1,2.6-0.3,2.1,1.8c-0.3,1.1,1.3,1.6,2.1,2.4
              c0.7,0.8,1.6,1.5,2.2,2.4c0.3,0.4,0.2,1.2,0.4,2.1c3.1,0.3,4.5,2.3,5.4,4.8c0.4,1.1,0.9,1.8,2,2.2c2.5,0.9,4.1,2.8,5,5.2
              c1,2.4,2.3,4.5,5.7,4.8c-0.2,1-0.5,2-0.7,3.1c2.2,0.6,4.5,1.3,6.9,1.9c0.7,3,1,4.4,5.7,4c2.7-1.8,5.3-3.6,8.2-5.6
              c1.6-2.4,1.4-4.2,0-5.7C216,92.6,214.5,92.4,215.2,90.6z M91,74.8c1.3-0.4,2.8-0.2,4.1-0.5c0.5-0.1,0.9-0.8,1.1-1
              c-1.5-1.5-2.8-2.8-3.8-3.9c-2.5,3-5,6-7.5,9c-1.4-1.1-2.3-1.8-3.2-2.5c-1.2,0.6-2.2,1.2-4.4,2.4c2.5-0.1,3.6-0.1,4.7-0.1
              c-0.6,1.2-1.1,2.3-1.7,3.5c-0.1,0-0.1,0-0.2-0.1c-0.2,0.5-0.4,1-0.6,1.5c0.2,0.1,0.4,0.1,0.6,0.2c0.1-0.5,0.3-1.1,0.4-1.6
              c-0.1,0-0.1,0-0.2-0.1c1.3-0.3,2.6-0.5,4.3-0.8c0-0.2,0.2-0.9,0.4-1.5c1,0.2,1.9,0.4,2.8,0.6c0.8-1.2,1.5-2.1,2.1-3
              c0,0.1,0,0.1-0.1,0.2c0.5,0.2,1,0.4,1.5,0.6c0.1-0.2,0.1-0.4,0.2-0.6c-0.5-0.1-1-0.3-1.6-0.4C90.3,76,90.5,74.9,91,74.8z
              M111.5,60.8c0.1-0.5,0.2-1.1,0.4-1.6c-0.5,0.2-0.9,0.5-1.4,0.7C110.9,60.2,111.2,60.5,111.5,60.8z"/>
          </defs>
          <clipPath id="logo12">
            <use xlink:href="#logo11"  style="overflow:visible;"/>
          </clipPath>
          <g class="st0">
            <path class="st1 ld1" d="M21.8,81.2c21.9-1.5,36.8-11.4,54-10.9"/>
            <path class="st2 ld2" d="M56.8,4.2C50,70.6,46.2,107,47.6,141c1.4,33.1-2.9,61-4.9,91.6"/>
            <line class="st2 ld3" x1="11.8" y1="157.2" x2="41.7" y2="145.8"/>
            <path class="st3 ld4" d="M133.8,19.2c0,0-4.1,40-59,65"/>
            <path class="st2 ld5" d="M132.8,31.2c0,0,64,44,81,74"/>
            <path class="st2 ld6" d="M99.8,118.2c4-4,65-12,72-9"/>
            <path class="st4 ld7" d="M107.8,178.2c0.1,8.6-8.6,23.9-8,46"/>
            <path class="st5 ld8" d="M94.8,171.1l77-24.9c0,0,8.2,67.7-5,82"/>
            <line class="st2 ld9" x1="101.7" y1="214.9" x2="165" y2="210.3"/>
          </g>
        </g>
      </svg>
      <svg version="1.1"  id="logo2" class="logo2 absolute" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve">
        <g>
          <g>
            <defs>
              <path id="logo21" d="M168.9,26.2c0.7,1.3,1,2.4,0.9,4c-0.8,9.4-0.8,18.9-2.3,28.1c-1,6-1,12.1-2.5,17.8
                c-2.4,9.3-2,18.8-3.1,28.2c-0.6,4.8-0.6,9.5-2.1,14.2c-0.6,1.6-1.8,3.6-0.8,6.2c0.7,1.9-0.6,4.9-0.2,7.3c0.3,2.1-2.3,3.1-0.7,5.6
                c0.8,1.3-0.2,4.3-0.3,6.5c-0.2,2.2-0.4,4.4-0.4,6.7c0,1.1,0.5,2.2,0.9,3.8c-2.3,4.7-2.4,9.8-1.7,14.7c0.3,2.2,0.2,3.9-0.4,6.1
                c-1.5,4.8-1.7,9.8-1.1,14.9c0.6,5,0.7,10-1.9,15c0.7,1.2,1.5,2.4,2.3,3.8c-1.5,4-2.9,8-4.5,12.3c1.2,5.5,1.7,8.1-2.4,13.9
                c-3.2,0.8-6.3,1.6-9.8,2.5c-2.6-1.4-9.9-11.9-9.5-16.1c0.2-2,1.3-4.4-0.3-6.3c-1.4-1.6,1.6-2.6,0.3-4.8c-1.4-2.5,0.9-5.7,0.5-9
                c-0.3-2.7-0.8-6.2,1.5-9c0.6-0.8-0.3-3.3-0.2-5c0.1-1.6,0.9-3.2,1-4.8c0.7-9.1,6-7.2,6.6-16.3c0.1-1.8,2-7.1,1.2-9.2
                c-0.7-1.9-0.4-5.4,0.6-7.2c1.6-3,1.3-6,1.7-9c0.4-2.8,0.3-5.6,1.7-8.3c1.2-2.3-0.5-5.9,1.8-8.2c0.3-0.3,0.3-1.4,0.2-2.1
                c-0.3-7.1,2.5-13.7,2.9-20.8c0.1-2.2,1.4-4.3,2.4-7.1c-0.1-0.1-0.2-0.5-0.5-0.9c2.3-7.7-5.3-16.9-0.5-23.9
                c-0.1-0.9-0.7-2.2-0.5-3.4c0.3-2,0.7-4.3,1.7-5.9c1.2-2,0.1-3.7,0.4-5.5c0.3-2,1.1-4,1.6-6c0.4-1.8-5.1-16-3.5-17.5
                c0.4-0.3,0.8-1.4,0.5-2c-3.4-6.9,2-14.8,3.1-22.5c4.5-8.7,14.4,14.2,14.9,15.2C168.6,23.4,168.3,25.2,168.9,26.2z M70.1,76.3
                c0,1,0.1,2,0.2,3c-1.5,1-3.1,2-4.8,3c0.7,3.3,2.1,2.4,3.8,0.7c0.3,0.3,0.9,0.7,0.8,1c-0.9,3.1,2,6.7-1.4,9.4
                c-0.2-0.4-0.3-0.8-0.5-1.2c-0.3,0.4-0.7,0.8-1,1.2c-0.5,0.3-1.1,0.6-2.1,1.2c1.1,1.3,1.9,2.3,2.8,3.3c0.7-0.6,1.2-1,2-1.7
                c0,2.1,0,3.7,0,5.9c-0.8,2.3-5.9,1.4-5.7,5.7c1.6-0.4,3-0.7,4.5-1l-0.1-0.1c0.3-0.4,0.7-0.8,1-1.2c0.2,0.2,0.3,0.3,0.5,0.5
                c-0.4,0.3-0.8,0.7-1.2,1l-0.1-0.1c0.4,0.8,0.9,1.5,1.2,2c-2.8,1.4-5.6,2.9-8.9,4.6c3.3,1.7,5.9,0.3,9.4-1.5
                c-0.8,1.9-1.4,2.8-1.6,3.7c-0.2,1.2-0.3,2.5,0,3.7c0.5,2.1-0.7,3.6-1.5,5.3c-0.4,0.2-0.8,0.3-1.2,0.5c0.4,0.3,0.8,0.7,1.2,1
                c-2,2.5-1.5,5.4-1.4,9c0.8,0.5,2,1.4,3.3,2.3c-0.8,1.4-1.4,2.3-2.2,3.8c1,1.1,2.1,2.3,3.1,3.3c-0.8,1.5-1.5,2.9-2.4,4.5
                c0.7,1.8,2.5,3.9-0.2,5.9c2,1.5-0.1,3.6,1.1,5.7c0.9,1.6-0.3,4.5-0.7,8.2c0.4,2,1.2,5.5,1.9,9c-0.8,0.3-1.6,0.6-3,1.1
                c1.3,0.4,2.1,0.7,3,1c-2,4-0.5,8.3-1.1,12.3c1.1,1.1,2,2,3,3c-0.4,0.5-0.8,1-1.2,1.5c0.5,0.9,1.1,1.9,1.6,2.7
                c-0.8,0.8-1.3,1.4-1.8,1.9c0.6,2,1.2,3.9,1.9,6.2c-1.8,0.3-3.2,0.5-4.9,0.8c0.6,5.7,2.3,10.9,5.9,15.3c-3.9,1.1-7.9,2.2-12.7,3.5
                c-0.5-0.7-1.5-1.9-3.4-4.2c2.7,0.4,4,0.6,5.7,0.9c0-0.9,0.5-2.4-0.1-3.2c-2.3-2.9-1.8-5.6-0.3-8.7c0.6-1.2,0.4-2.8,0.4-4.3
                c0-8.2,0.4-16.4-0.1-24.5c-0.3-5.9-2-11.8-1.2-18.5c-1.2,0.8-1.9,1.2-2.9,1.8c-0.6-2.8,1.7-4.3,2.1-6.8c-1.6-2.9,0.3-6.8-1-9.8
                c-1.6-3.6-1.2-7.1-1.2-10.6c0-3.2-0.2-6.5-1.3-8.9c1.7-4.7,2.9-9,1.3-13.7c0.5-0.5,1-1.1,1.3-1.5c-2.3-3.7-1.5-7.4-0.7-11.2
                c0.2-1.1,1.1-2.4,0.7-3.3c-0.7-1.9-2.5-3.4-0.7-5.8c1-1.3-2.4-3.8,0.8-5.1c-0.6-3.8,1.2-8-1.1-11.8c-1.1,0.3-2.2,0.8-3.2,1.4
                c-1.1,0.7-2.9,0.3-4.9,0.5c-1,1.5-2.3,3.3-3.7,5.4c-2.6-0.9-6-1-6.6-2.4c-1.2-2.5-3-3.2-5.1-4.1c2.2-1.1,4.4-2.1,4.3-5.3
                c1.1-0.1,2.3-0.3,3.5-0.4c0.9-2.1,1.3-4.7,4.5-5l6.1,0.3c2.2,0.3,3.5,0.6,4.9,0.8c-0.3,0.5-0.7,1-1,1.5c0.4,0,0.9-0.1,1.3-0.1
                c0-0.1-0.1-0.2-0.1-0.3c0.6-0.4,1.6-1,2.6-1.6c-0.9-2.3-2.4-4.2-0.7-6c2.1-2.3,0.4-3.9-0.5-5.5c1-4,2.3-7.8,2.7-11.6
                s1.6-7.2,2.9-10.7c0.5-1.3,0.8-2.6,1-4c-0.5,0.1-1.1,0.3-1.6,0.5c-3.4,1.6-7.1-0.8-10.4,2c-1.4,1.2-4.7,0.2-7.1,0.2
                c-0.8-0.8-1.5-1.5-2.5-2.5c0.6-2.2,1.2-4.7,1.8-7.3c4.5-1.8,8.8-4,14-3.7c1.5,0.1,3-1,4.6-1.4c0.2,0,0.3-0.1,0.5-0.1
                C67,18,67.9,17.8,69,18c1.4-0.3,2.8-0.6,4.2-0.7c2.4-0.2,4.9,0,7.4-0.1c1.6-0.1,3.1-0.5,5-0.8c-0.5,6.3-3.2,11.4-9.6,14
                c-1.5,0.1-3,0-4.6,0c0.4,3.1,0.8,6.2,0.6,9.3c-0.1,1.9,0.5,3.7-1.1,5.5c-2.1,2.4,1.5,5.3,0,8.1c2.6,2.3,0.4,4.3-0.5,6.6
                c0.4,0.6,1,1.4,1.5,2.1c-0.9,0.9-1.8,1.8-2.5,2.5c0.9,1.7,1.2,3.5,1.3,5.3c6.7-0.5,13.4-1,20-1.5c0.8,0.7,1.4,1.2,2.1,1.8
                c-1.7,1.4-3.3,2.8-5.1,4.3c-3.8,0.3-7.8,0.5-11.7,0.9C73.7,75,71.9,75.7,70.1,76.3z M68.2,68c0-0.5,0-1,0-1.5c-0.2,0-0.3,0-0.5,0
                c0,0.5,0,1,0,1.5C67.9,68,68,68,68.2,68z M64.5,168.9c0.4,0,0.8-0.1,1.3-0.1c-0.1-1.8-0.2-3.6-0.4-5.4c-0.3,0-0.6,0-0.9,0.1
                C64.5,165.3,64.5,167.1,64.5,168.9z M64.6,170.6c0,1.5,0,3,0,4.5c0.2,0,0.5,0,0.7,0c0-1.5,0-3,0-4.5
                C65.1,170.6,64.9,170.6,64.6,170.6z M64.4,190.5c0,0.2,0.1,0.5,0.1,0.7c0.8,0,1.6,0,2.5,0c0-0.4-0.1-0.9-0.1-1.3
                C66,190.1,65.2,190.3,64.4,190.5z M63,154c0.9-2.8,1.8-5.7,0-8.4C63,148.4,63,151.2,63,154z M67.6,88.8c0.2,0,0.5,0,0.7,0
                c0-1,0-2,0-3c-0.2,0-0.5,0-0.7,0C67.6,86.8,67.6,87.8,67.6,88.8z M65.2,88.8c-0.2,0-0.3,0-0.5,0c0,0.5,0,1,0,1.5
                c0.2,0,0.3,0,0.5,0C65.2,89.8,65.2,89.3,65.2,88.8z M197.6,66c-0.7,1.9-1.2,3.4-1.9,4.8c-1.2,2.2-2.7,4.2-3.7,6.4
                c-1.1,2.3-1.8,4.8-2.6,7.2c-0.5,1.5-0.3,3.4-1.2,4.6c-3,4.2-3.4,9.1-4.1,13.9c1.9,1.9,3.7,3.7,5.3,5.3c1.5-0.4,2.4-0.6,3.5-0.9
                c1.2-2,3.8-4.4,3.5-6.2c-0.7-4.2,3.3-6.1,3.6-9.9c0.3-3.4,3-6.5,4.7-9.7C205.7,74.6,202.7,69.6,197.6,66z M127,77.3
                c-4-1.4-8-2.7-12.1-4.1c-0.9,3.4-2.6,6.8-2.1,10.7c0.1,0.8-0.6,1.7-0.9,2.5c-1.9,4.3,0.3,8.4,1.2,12.4c0.6,2.9,4.3,1.3,6,3.1
                c0.2,0.2,0.3,0.3,0.4,0.4c1.3-0.7,2.5-1.3,3.9-2c1.5-3.9,2.7-8.1,4.6-12C129.8,84.4,129.6,80.8,127,77.3z M101.6,171.8
                c0.4,1.5,0.9,3,1.3,4.6c-1.5,1.3-2.7,2.4-4.2,3.7c-1.6,0.2-3.7,0.6-6.4,1c0.1,0.1-0.6-0.5-1.2-1.1c0.3-1.9,3.2-3.9-0.3-5.6
                c0.5-0.8,0.9-1.6,1.5-2.6c-1.4-0.2-2.8-0.5-5.3-0.9c1.8-0.8,2.7-1.1,3.8-1.6c-1.1-1.2-2.1-2.4-3.4-3.7c0.7-0.9,1.2-1.7,1.9-2.6
                c-0.8-0.3-1.6-0.6-2.5-0.8c0.3-1.3,0.7-2.6,1.2-5c-1.4,1.3-1.9,1.8-2.6,2.3c-1.9-9.6-1.7-19.6-6.9-29.1c1-0.3,2.2-0.8,3.6-1.3
                c10.7,5.6,13.6,17.6,17.9,28.9c-1.2,1.4-2.3,2.5-3.6,4c1.8,0.3,3.2,0.5,4.6,0.7c-0.7,1-1.1,1.7-1.6,2.5c0.6,1.1,1.1,2.1,1.7,3.2
                c0,0-0.1-0.1-0.2-0.2c-0.4,0.3-0.8,0.7-1.2,1c0.2,0.2,0.3,0.3,0.5,0.5c0.3-0.4,0.7-0.8,1-1.3
                C104.3,169.4,101.9,170.7,101.6,171.8z M96.1,168.3c-0.2,0-0.3,0-0.5,0c0,0.5,0,1,0,1.5c0.2,0,0.3,0,0.5,0
                C96.1,169.3,96.1,168.8,96.1,168.3z M29.1,117.3c-0.4,0.4-0.9,0.9-2,2c0,1.7,0.3,3.4-0.1,5.1c-0.4,1.8-1.7,3.2-1.5,5.3
                c0.1,2-1,4-1.3,6c-0.2,1.9,0.8,4.8-0.2,5.7c-1.8,1.7-0.5,3.5-1,5.1c-0.7,2.2,1,5.2,1.5,7.4c1.8-0.6,3.4-1.1,4.8-1.5
                c1.8-1.6,3.6-3.3,5-4.5c0.9-10.2,5.1-19.4,4.6-28.8C35.1,118.4,32.1,117.8,29.1,117.3z M68.4,125.5c-0.4-0.3-0.8-0.7-1.2-1
                c0,0.5,0,1,0,1.5C67.6,125.8,68,125.7,68.4,125.5z M68.2,133.4c0-0.5,0-1,0-1.5c-0.2,0-0.3,0-0.5,0c0,0.5,0,1,0,1.5
                C67.9,133.4,68,133.4,68.2,133.4z M100.1,145.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.3-0.8,0.7-1.2,1c0.2,0.2,0.3,0.3,0.5,0.5
                C99.4,146.3,99.7,145.9,100.1,145.5z M67.7,94.5c0.3-0.4,0.7-0.8,1-1.2c-0.5,0-1,0-1.5,0C67.4,93.7,67.5,94.1,67.7,94.5z
                M70.7,228.1c0.2,0,0.3,0,0.5,0c0-0.5,0-1,0-1.5c-0.2,0-0.3,0-0.5,0C70.7,227.1,70.7,227.6,70.7,228.1z M198.4,207.9
                c2.4-4.2-1.3-7.2-1-10.4c-1.3-0.4-1.9-0.6-2.2-0.7c0.4-1.5,0.7-2.8,1-4.1c-0.4-0.3-0.8-0.7-1.2-1c0.4-0.2,0.8-0.3,1.2-0.5
                c0-0.9,0-1.8,0-2.6c-1.3-0.4-2.2-0.7-3.6-1.2c3.6-0.3,3.7-2.9,4.8-4.6c-1.8-0.9-3.6-1.8-6.2-3c2.4-0.4,3.7-0.7,6.6-1.3
                c-3-0.6-4.5-0.9-6.9-1.4c1.8-0.6,2.6-0.9,3.2-1.1c-1.5-3.5-2.9-7-4.9-11.7c-3.1-2.7-7.2-6.5-11.4-10.1c-0.9-0.8-2.3-0.9-4.2-1.6
                c2.7,8.4,9.4,14.3,10.9,22.9c1.4,1.3,3,2.8,3.9,3.7c0.9,4.8,1.1,8.6,3.5,12.2c0,2.1,0,4.5,0,7C196,200.3,194,206.1,198.4,207.9z
                M202.2,218l0.1,0.1c0.4-0.3,0.8-0.7,1.2-1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.3,0.4-0.7,0.8-1,1.2c-0.5-0.6-1.3-1.2-1.3-1.7
                c0.2-1.3,1-2.5,1.3-3.8c0.1-0.5-0.4-1.1-0.4-1.4c-2,0.6-3.7,1.2-5,1.6c1.5,3.5,3,7,4.6,10.5c-1.6,0.8-2.6,1.3-3.6,1.7
                c0.1,1.3,0.1,2.4,0.2,4.9c1-2.2,1.5-3.2,1.9-4.2c0.8,1,1.6,2,2.3,2.9l-0.1,0.1c0.3,0.4,0.7,0.8,1,1.2c0.2-0.2,0.3-0.3,0.5-0.5
                c-0.4-0.3-0.8-0.7-1.2-1l-0.1,0.1c0.3-1.3,0.7-2.5,1.1-4.1c-0.1-0.1-0.6-0.6-1.1-1c0.6-0.8,1.2-1.5,1.7-2.1
                C203.3,219.9,202.7,219,202.2,218z M197.5,192.2c-0.4-0.3-0.8-0.7-1.2-1c0,0.5,0,1,0,1.5C196.7,192.5,197.1,192.4,197.5,192.2z"
                />
            </defs>
            <clipPath id="logo22">
              <use xlink:href="#logo21"  style="overflow:visible;"/>
            </clipPath>
            <g class="st1">
              <path class="st2 ld10" d="M92.8,16.2c-14.3-2.8-54,7-54,7"/>
              <path class="st3 ld11" d="M36.8,84.2c15.2-20.7,57-8,57-8"/>
              <path class="st2 ld12" d="M65.8,24c0,0.2-5,206.2-5,206.2"/>
              <line class="st2 ld13" x1="34.8" y1="106.2" x2="27.8" y2="158.2"/>
              <path class="st2 ld14" d="M78.8,124.2c-1,1,21,62,21,62"/>
              <path class="st2 ld15" d="M123.8,68.2c3,3-7,38-7,38"/>
              <path class="st2 ld16" d="M204.8,56.2c0,3-21,52-21,52"/>
              <path class="st2 ld17" d="M155.8,4.2c14,76-18.8,190.8-15,235"/>
              <path class="st2 ld18" d="M169.8,149.2c4,1,39,78,39,78"/>
            </g>
          </g>
        </g>
      </svg>
      <div class="logo_txt absolute"
        data-aos="fade-up"
        data-aos-delay="800"><span>宜真建築</span>展宜建築<span class="x"></span>真實建築</div>
    <div
      v-if="!isMobile"
    >
    <svg class="logo absolute" version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 888 241" style="enable-background:new 0 0 888 241;" xml:space="preserve">
        <path class="st2" d="M472.5,92.6c-0.1,0-0.2,0-0.2,0.2l-1.4,3.4c-0.3,0.6-0.4,1.2-0.4,1.5c0,0.4,0.2,0.7,1,0.7h0.4
          c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.4,0.3c-0.9,0-2.1-0.1-2.9-0.1c-0.3,0-1.7,0.1-3,0.1c-0.3,0-0.5-0.1-0.5-0.3
          c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.5,0,0.8,0c1.2-0.2,1.6-0.9,2.2-2.2l6.8-15.8c0.3-0.7,0.5-1,0.8-1c0.4,0,0.5,0.2,0.8,0.8
          c0.6,1.4,5,11.6,6.6,15.4c1,2.3,1.7,2.6,2.2,2.7c0.4,0.1,0.7,0.1,1,0.1c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-1.1,0.3
          c-0.9,0-2.8,0-5-0.1c-0.5,0-0.8,0-0.8-0.2c0-0.1,0.1-0.2,0.3-0.3c0.2,0,0.4-0.3,0.2-0.6l-2-5c-0.1-0.1-0.1-0.2-0.3-0.2H472.5z
          M478,91.2c0.1,0,0.1-0.1,0.1-0.1l-2.4-6.4c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.1,0.2l-2.5,6.3c-0.1,0.1,0,0.2,0.1,0.2H478z
          M502.3,91.6c0,3.2,0,4.8,0.6,5.3c0.4,0.4,1.4,0.5,3.4,0.5c1.4,0,2.4,0,3-0.7c0.3-0.3,0.6-1,0.6-1.5c0-0.2,0.1-0.4,0.3-0.4
          c0.2,0,0.2,0.3,0.2,0.6c0,0.3-0.2,2.2-0.4,2.9c-0.2,0.6-0.3,0.7-1.7,0.7c-1.9,0-3.4,0-4.7-0.1c-1.3,0-2.4-0.1-3.4-0.1
          c-0.3,0-0.9,0-1.5,0c-0.6,0-1.3,0.1-1.8,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1
          c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-3,0.1-5.3v-4.4c0-3.8,0-4.5,0-5.3c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.5-0.1-0.9-0.1
          c-0.3,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2c1.2,0,2.9,0.1,3.6,0.1c0.6,0,2.7-0.1,3.7-0.1c0.4,0,0.6,0.1,0.6,0.2s-0.1,0.3-0.5,0.3
          c-0.3,0-0.7,0-1,0.1c-0.7,0.1-0.9,0.5-1,1.4c-0.1,0.8-0.1,1.5-0.1,5.3V91.6z M515.1,87.2c0-3.8,0-4.5,0-5.3
          c-0.1-0.9-0.3-1.1-0.9-1.3c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.4-0.1-0.4-0.3c0-0.1,0.2-0.2,0.6-0.2c1,0,2.7,0.1,3.5,0.1
          c0.6,0,2.3-0.1,3.3-0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.1,0.3-0.4,0.3c-0.3,0-0.5,0-0.8,0.1c-0.7,0.1-0.9,0.5-1,1.4
          c-0.1,0.8-0.1,1.5-0.1,5.3v4.4c0,2.4,0,4.4,0.1,5.5c0.1,0.7,0.3,1.1,1,1.2c0.4,0.1,0.9,0.1,1.3,0.1c0.3,0,0.4,0.1,0.4,0.3
          c0,0.2-0.2,0.3-0.5,0.3c-1.7,0-3.5-0.1-4.2-0.1c-0.6,0-2.4,0.1-3.4,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3
          c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.5,0.8-1.2c0.1-1,0.1-3,0.1-5.5V87.2z M530.6,91.7c0,2.4,0,4.2,0.1,5.2c0.1,0.7,0.3,1.2,1,1.3
          c0.4,0.1,0.9,0.1,1.3,0.1c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.6,0.3c-1.7,0-3.5-0.1-4.2-0.1c-0.7,0-2.3,0.1-3.3,0.1
          c-0.4,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-2.9,0.1-5.3v-4.4
          c0-3.8,0-4.5,0-5.3c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.6-0.1-1-0.1c-0.2,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2c1.3,0,3,0.1,3.7,0.1
          c1,0,6.8,0,7.4,0c0.5,0,0.8-0.1,1.1-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.1,0.5-0.2,1.1c0,0.3-0.1,1.7-0.1,2
          c0,0.2-0.1,0.5-0.3,0.5c-0.2,0-0.3-0.1-0.3-0.4c0-0.2,0-0.6-0.1-0.9c-0.2-0.4-0.7-0.7-1.7-0.8l-4.3-0.1c-0.1,0-0.2,0.1-0.2,0.4v6.1
          c0,0.1,0,0.2,0.2,0.2l3.6,0c0.4,0,0.8,0,1.2,0c0.5,0,0.9-0.1,1.1-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.1,0.1,0.1,0.2
          c0,0.1-0.1,0.8-0.2,1.6c-0.1,0.5-0.1,1.7-0.1,1.8c0,0.2,0,0.6-0.3,0.6c-0.1,0-0.2-0.2-0.2-0.4c0-0.2-0.1-0.5-0.2-0.8
          c-0.1-0.4-0.4-0.9-1.2-0.9c-0.6-0.1-3.7-0.1-4.2-0.1c-0.1,0-0.2,0-0.2,0.2V91.7z M543.2,87.2c0-3.8,0-4.5,0-5.3
          c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.6-0.1-1-0.1c-0.3,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2c1.4,0,3,0.1,3.7,0.1c0.8,0,6.6,0,7.1,0
          c0.5,0,0.9-0.1,1.1-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.1,0.5-0.2,1.6c0,0.3-0.1,1.4-0.1,1.7
          c0,0.1-0.1,0.4-0.3,0.4c-0.2,0-0.2-0.1-0.2-0.3c0-0.2,0-0.6-0.2-0.9c-0.2-0.4-0.4-0.8-1.7-0.9c-0.4-0.1-3.5-0.1-4.1-0.1
          c-0.1,0-0.2,0.1-0.2,0.2v6.2c0,0.1,0,0.3,0.2,0.3c0.6,0,4,0,4.5-0.1c0.6-0.1,1-0.1,1.2-0.4c0.2-0.2,0.3-0.3,0.4-0.3
          c0.1,0,0.2,0.1,0.2,0.3s-0.1,0.6-0.2,1.8c-0.1,0.5-0.1,1.5-0.1,1.7c0,0.2,0,0.6-0.3,0.6c-0.2,0-0.2-0.1-0.2-0.2c0-0.3,0-0.6-0.1-0.9
          c-0.1-0.5-0.5-0.9-1.4-1c-0.5-0.1-3.4-0.1-4.1-0.1c-0.1,0-0.1,0.1-0.1,0.3v2c0,0.9,0,3,0,3.7c0.1,1.7,0.9,2.1,3.5,2.1
          c0.7,0,1.8,0,2.4-0.3c0.6-0.3,0.9-0.8,1.1-1.9c0.1-0.3,0.1-0.4,0.3-0.4c0.2,0,0.2,0.3,0.2,0.6c0,0.6-0.2,2.3-0.4,2.8
          c-0.2,0.6-0.4,0.6-1.4,0.6c-1.9,0-3.5,0-4.8-0.1c-1.3,0-2.2-0.1-3-0.1c-0.3,0-0.9,0-1.5,0c-0.6,0-1.3,0.1-1.8,0.1
          c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-3,0.1-5.3V87.2z
          M572.5,91.6c0,3.2,0,4.8,0.6,5.3c0.4,0.4,1.4,0.5,3.4,0.5c1.4,0,2.4,0,3-0.7c0.3-0.3,0.6-1,0.6-1.5c0-0.2,0.1-0.4,0.3-0.4
          c0.2,0,0.2,0.3,0.2,0.6c0,0.3-0.2,2.2-0.4,2.9c-0.2,0.6-0.3,0.7-1.7,0.7c-1.9,0-3.4,0-4.7-0.1c-1.3,0-2.4-0.1-3.4-0.1
          c-0.3,0-0.9,0-1.5,0c-0.6,0-1.3,0.1-1.8,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1
          c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-3,0.1-5.3v-4.4c0-3.8,0-4.5,0-5.3c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.5-0.1-0.9-0.1
          c-0.3,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2c1.2,0,2.9,0.1,3.6,0.1c0.6,0,2.7-0.1,3.7-0.1c0.4,0,0.6,0.1,0.6,0.2s-0.1,0.3-0.5,0.3
          c-0.3,0-0.7,0-1,0.1c-0.7,0.1-0.9,0.5-1,1.4c-0.1,0.8-0.1,1.5-0.1,5.3V91.6z M585.3,87.2c0-3.8,0-4.5,0-5.3
          c-0.1-0.9-0.3-1.1-0.9-1.3c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.4-0.1-0.4-0.3c0-0.1,0.2-0.2,0.6-0.2c1,0,2.7,0.1,3.5,0.1
          c0.6,0,2.3-0.1,3.3-0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.1,0.3-0.4,0.3c-0.3,0-0.5,0-0.8,0.1c-0.7,0.1-0.9,0.5-1,1.4
          c-0.1,0.8-0.1,1.5-0.1,5.3v4.4c0,2.4,0,4.4,0.1,5.5c0.1,0.7,0.3,1.1,1,1.2c0.4,0.1,0.9,0.1,1.3,0.1c0.3,0,0.4,0.1,0.4,0.3
          c0,0.2-0.2,0.3-0.5,0.3c-1.7,0-3.5-0.1-4.2-0.1c-0.6,0-2.4,0.1-3.4,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3
          c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.5,0.8-1.2c0.1-1,0.1-3,0.1-5.5V87.2z M594.9,82.4c-0.6-1.4-1-1.6-1.9-1.9
          c-0.4-0.1-0.8-0.1-1-0.1c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.3-0.2,0.6-0.2c1.4,0,2.9,0.1,3.8,0.1c0.6,0,2-0.1,3.3-0.1
          c0.3,0,0.6,0.1,0.6,0.2s-0.2,0.3-0.4,0.3c-0.4,0-0.8,0-1,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3,0.2,1,0.6,1.9l4.4,11.2h0.1
          c1.1-2.6,4.5-10.7,5.1-12.3c0.1-0.3,0.2-0.7,0.2-0.9c0-0.3-0.1-0.4-0.4-0.4c-0.3-0.1-0.7-0.1-1-0.1c-0.2,0-0.4-0.1-0.4-0.3
          s0.2-0.2,0.7-0.2c1.3,0,2.4,0.1,2.8,0.1c0.4,0,1.7-0.1,2.4-0.1c0.3,0,0.5,0.1,0.5,0.2s-0.1,0.3-0.4,0.3c-0.3,0-0.8,0-1.3,0.3
          c-0.3,0.2-0.7,0.6-1.4,2.2c-1,2.4-1.7,3.7-3.1,6.8c-1.7,3.7-2.9,6.4-3.4,7.7c-0.6,1.4-0.9,1.8-1.2,1.8s-0.6-0.3-1.1-1.5L594.9,82.4z
          M616.1,87.2c0-3.8,0-4.5,0-5.3c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.6-0.1-1-0.1c-0.3,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2
          c1.4,0,3,0.1,3.7,0.1c0.8,0,6.6,0,7.1,0c0.5,0,0.9-0.1,1.1-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.1,0.5-0.2,1.6
          c0,0.3-0.1,1.4-0.1,1.7c0,0.1-0.1,0.4-0.3,0.4c-0.2,0-0.2-0.1-0.2-0.3c0-0.2,0-0.6-0.2-0.9c-0.2-0.4-0.4-0.8-1.7-0.9
          c-0.4-0.1-3.5-0.1-4.1-0.1c-0.1,0-0.2,0.1-0.2,0.2v6.2c0,0.1,0,0.3,0.2,0.3c0.6,0,4,0,4.5-0.1c0.6-0.1,1-0.1,1.2-0.4
          c0.2-0.2,0.3-0.3,0.4-0.3c0.1,0,0.2,0.1,0.2,0.3s-0.1,0.6-0.2,1.8c-0.1,0.5-0.1,1.5-0.1,1.7c0,0.2,0,0.6-0.3,0.6
          c-0.2,0-0.2-0.1-0.2-0.2c0-0.3,0-0.6-0.1-0.9c-0.1-0.5-0.5-0.9-1.4-1c-0.5-0.1-3.4-0.1-4.1-0.1c-0.1,0-0.1,0.1-0.1,0.3v2
          c0,0.9,0,3,0,3.7c0.1,1.7,0.9,2.1,3.5,2.1c0.7,0,1.8,0,2.4-0.3c0.6-0.3,0.9-0.8,1.1-1.9c0.1-0.3,0.1-0.4,0.3-0.4
          c0.2,0,0.2,0.3,0.2,0.6c0,0.6-0.2,2.3-0.4,2.8c-0.2,0.6-0.4,0.6-1.4,0.6c-1.9,0-3.5,0-4.8-0.1c-1.3,0-2.2-0.1-3-0.1
          c-0.3,0-0.9,0-1.5,0c-0.6,0-1.3,0.1-1.8,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1
          c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-3,0.1-5.3V87.2z M632.5,87.2c0-3.8,0-4.5,0-5.3c-0.1-0.9-0.3-1.1-1.1-1.4
          c-0.2-0.1-0.6-0.1-1-0.1c-0.3,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2c1.4,0,3,0.1,3.7,0.1c0.8,0,2.7-0.1,4.2-0.1c3.2,0,7.6,0,10.4,2.9
          c1.3,1.3,2.4,3.4,2.4,6.4c0,3.2-1.4,5.5-2.8,7c-1.2,1.2-3.9,2.9-8.7,2.9c-0.9,0-2.1-0.1-3.1-0.1c-1-0.1-2-0.1-2.6-0.1
          c-0.3,0-0.9,0-1.5,0c-0.6,0-1.3,0.1-1.8,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1
          c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-3,0.1-5.3V87.2z M636,90c0,2.7,0,4.6,0.1,5.1c0,0.6,0.1,1.5,0.3,1.8c0.3,0.5,1.4,1,3.4,1
          c2.4,0,4.1-0.4,5.7-1.7c1.7-1.4,2.2-3.6,2.2-6.2c0-3.2-1.3-5.2-2.4-6.2c-2.4-2.1-5.2-2.4-7.3-2.4c-0.5,0-1.4,0.1-1.6,0.2
          c-0.2,0.1-0.3,0.2-0.3,0.5c0,0.9,0,2.9,0,4.9V90z M664.4,98.6c-0.4-0.2-0.5-0.3-0.5-0.9c0-1.4,0.1-2.9,0.1-3.3
          c0-0.4,0.1-0.6,0.3-0.6c0.2,0,0.3,0.2,0.3,0.4c0,0.3,0.1,0.9,0.2,1.3c0.6,1.9,2.5,2.5,4.1,2.5c2.2,0,3.6-1.4,3.6-3.2
          c0-1.1-0.3-2.2-2.6-3.5l-1.5-0.9c-3.1-1.8-4.1-3.5-4.1-5.7c0-3.1,2.9-5,6.5-5c1.7,0,3,0.2,3.7,0.4c0.2,0.1,0.4,0.1,0.4,0.3
          c0,0.3-0.1,1-0.1,3c0,0.5-0.1,0.8-0.3,0.8c-0.2,0-0.3-0.1-0.3-0.4c0-0.2-0.1-1-0.7-1.7c-0.4-0.5-1.2-1.2-2.9-1.2
          c-1.9,0-3.5,1-3.5,2.7c0,1.1,0.4,2,2.7,3.3l1.1,0.6c3.4,1.9,4.5,3.8,4.5,6.1c0,1.9-0.7,3.4-2.6,4.7c-1.2,0.9-2.9,1-4.3,1
          C667.1,99.2,665.5,99.1,664.4,98.6z M684.6,91.6c0,3.2,0,4.8,0.6,5.3c0.4,0.4,1.4,0.5,3.4,0.5c1.4,0,2.4,0,3-0.7
          c0.3-0.3,0.6-1,0.6-1.5c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.2,0.3,0.2,0.6c0,0.3-0.2,2.2-0.4,2.9c-0.2,0.6-0.3,0.7-1.7,0.7
          c-1.9,0-3.4,0-4.7-0.1c-1.3,0-2.4-0.1-3.4-0.1c-0.3,0-0.9,0-1.5,0c-0.6,0-1.3,0.1-1.8,0.1c-0.3,0-0.5-0.1-0.5-0.3
          c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.6,0.8-1.3c0.1-1.1,0.1-3,0.1-5.3v-4.4c0-3.8,0-4.5,0-5.3
          c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.5-0.1-0.9-0.1c-0.3,0-0.4-0.1-0.4-0.3s0.2-0.2,0.6-0.2c1.2,0,2.9,0.1,3.6,0.1
          c0.6,0,2.7-0.1,3.7-0.1c0.4,0,0.6,0.1,0.6,0.2s-0.1,0.3-0.5,0.3c-0.3,0-0.7,0-1,0.1c-0.7,0.1-0.9,0.5-1,1.4
          c-0.1,0.8-0.1,1.5-0.1,5.3V91.6z M694,89.4c0-4.3,3-9.8,10.9-9.8c6.5,0,10.6,3.6,10.6,9.4s-4.2,10.3-10.9,10.3
          C697.1,99.2,694,93.9,694,89.4z M711.7,89.9c0-5.5-3.3-9-7.5-9c-2.9,0-6.3,1.5-6.3,7.7c0,5.2,2.9,9.2,7.8,9.2
          C707.5,97.9,711.7,97,711.7,89.9z M718.8,82.4c-0.3-0.9-0.5-1.5-0.9-1.7c-0.3-0.2-0.8-0.2-1-0.2c-0.3,0-0.4-0.1-0.4-0.3
          s0.2-0.2,0.6-0.2c1.3,0,2.8,0.1,3,0.1c0.2,0,1.5-0.1,3-0.1c0.4,0,0.6,0.1,0.6,0.2s-0.2,0.3-0.5,0.3c-0.2,0-0.4,0-0.6,0.1
          c-0.2,0.1-0.3,0.2-0.3,0.5c0,0.3,0.2,1.1,0.6,2.3c0.4,1.7,2.8,9.2,3.2,10.4h0.1l5-12.8c0.3-0.7,0.5-0.9,0.8-0.9
          c0.3,0,0.5,0.4,0.9,1.2l5.2,12.4h0.1c0.5-1.4,2.5-8,3.4-11c0.2-0.6,0.3-1.1,0.3-1.4c0-0.4-0.2-0.7-1.3-0.7c-0.2,0-0.4-0.1-0.4-0.3
          c0-0.2,0.2-0.2,0.6-0.2c1.4,0,2.5,0.1,2.8,0.1c0.2,0,1.3-0.1,2.2-0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.1,0.3-0.4,0.3
          c-0.3,0-0.6,0-0.9,0.1c-0.6,0.2-0.9,1.2-1.4,2.6c-1.2,3.2-3.9,11.1-5.1,14.9c-0.3,0.9-0.4,1.2-0.8,1.2c-0.4,0-0.5-0.3-0.9-1.2
          l-5.3-12.4H731c-0.4,1.1-3.9,9.9-5,12.2c-0.5,1-0.6,1.3-1,1.3c-0.3,0-0.5-0.3-0.7-0.9L718.8,82.4z M752.9,91.6c0,3.2,0,4.8,0.6,5.3
          c0.4,0.4,1.4,0.5,3.4,0.5c1.4,0,2.4,0,3-0.7c0.3-0.3,0.6-1,0.6-1.5c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.2,0.3,0.2,0.6
          c0,0.3-0.2,2.2-0.4,2.9c-0.2,0.6-0.3,0.7-1.7,0.7c-1.9,0-3.4,0-4.7-0.1c-1.3,0-2.4-0.1-3.4-0.1c-0.3,0-0.9,0-1.5,0
          c-0.6,0-1.3,0.1-1.8,0.1c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.6,0.8-1.3
          c0.1-1.1,0.1-3,0.1-5.3v-4.4c0-3.8,0-4.5,0-5.3c-0.1-0.9-0.3-1.1-1.1-1.4c-0.2-0.1-0.5-0.1-0.9-0.1c-0.3,0-0.4-0.1-0.4-0.3
          s0.2-0.2,0.6-0.2c1.2,0,2.9,0.1,3.6,0.1c0.6,0,2.7-0.1,3.7-0.1c0.4,0,0.6,0.1,0.6,0.2s-0.1,0.3-0.5,0.3c-0.3,0-0.7,0-1,0.1
          c-0.7,0.1-0.9,0.5-1,1.4c-0.1,0.8-0.1,1.5-0.1,5.3V91.6z M767.3,92.4c0-1.4-0.2-1.9-0.6-2.6c-0.2-0.4-3.7-5.9-4.6-7.2
          c-0.6-0.9-1.2-1.5-1.7-1.8c-0.3-0.2-0.8-0.3-1.1-0.3c-0.2,0-0.4-0.1-0.4-0.3s0.2-0.2,0.5-0.2c0.4,0,2.4,0.1,3.2,0.1
          c0.6,0,1.8-0.1,2.9-0.1c0.2,0,0.4,0.1,0.4,0.2c0,0.2-0.2,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.4,0.4s0.1,0.5,0.3,0.9
          c0.5,0.8,3.9,6.6,4.3,7.3c0.3-0.6,3.5-6,3.9-6.7c0.3-0.6,0.4-1,0.4-1.3c0-0.2-0.1-0.5-0.6-0.6c-0.2-0.1-0.5-0.1-0.5-0.3
          c0-0.2,0.1-0.2,0.4-0.2c0.9,0,1.8,0.1,2.5,0.1c0.6,0,2.3-0.1,2.8-0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.2,0.3-0.5,0.3
          c-0.3,0-0.7,0.1-1.1,0.3c-0.6,0.3-0.8,0.5-1.4,1.2c-0.9,1-4.6,7-5,8c-0.4,0.9-0.3,1.7-0.3,2.5v2c0,0.4,0,1.4,0.1,2.5
          c0.1,0.7,0.3,1.2,1.1,1.3c0.4,0.1,0.9,0.1,1.3,0.1c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.6,0.3c-1.6,0-3.4-0.1-4.1-0.1
          c-0.6,0-2.4,0.1-3.4,0.1c-0.4,0-0.6-0.1-0.6-0.3c0-0.1,0.1-0.3,0.4-0.3c0.4,0,0.7,0,0.9-0.1c0.5-0.1,0.7-0.6,0.8-1.3
          c0.1-1.1,0.1-2.1,0.1-2.5V92.4z"/>

        <path class="st3" d="M816.6,92.2c-0.8-1.9-1.3-4-1.3-6.2s0.5-4.3,1.3-6.2h7.9v12.5H816.6z M824.5,78.6v-6.9
        c-3.2,1.4-5.8,3.8-7.4,6.9H824.5z M835.6,89.2h2.4v1.5h7.6c0.4-1.2,0.6-2.6,0.7-3.9h-5v-3h4.8c-0.3-2.4-1.2-4.6-2.4-6.5l-9.8,8.6
        c0,0-2.6-1.2-4.8-3.4c6-0.7,10.9-5,13-7.2c-2.8-3-6.9-4.9-11.3-4.9c-1.4,0-2.7,0.2-4,0.5v30c1.3,0.3,2.6,0.5,4,0.5
        c1.7,0,3.3-0.3,4.8-0.8V89.2z M824.5,93.3h-7.4c1.6,3,4.2,5.5,7.4,6.9V93.3z M838,91.9v7.9c3.2-1.7,5.8-4.5,7.2-7.9H838z"/>
      </svg>
     
      <!-- <img src="./s1/slogo.png" alt="" class="slogo"> -->
      <img
        src="./s1/txt.png"
        alt=""
        class="txt absolute"
        data-aos="fade-up"
        data-aos-delay="400"
      >
      <div
        class="btn absolute"
        v-scroll-to="{ element: `#contact` }"
        data-aos="fade"
        data-aos-delay="1000"
      >
        <h3>立即預約</h3>
      </div>
    </div>

    <div
      v-if="isMobile"
    >
      <img
        src="./mo/1/logo_m.png"
        alt=""
        class="logo absolute"
        data-aos="fade-up"
        data-aos-delay="100"
      >
      <img
        src="./mo/1/txt_m.png"
        alt=""
        class="txt absolute"
        data-aos="fade-up"
        data-aos-delay="400"
      >
      
      <div
        class="btn absolute"
        v-scroll-to="{ element: `#contact`, offset:!isMobile?-50: 0 }"
        data-aos="fade"
        data-aos-delay="1000"
      >
        <h3>立即預約</h3>
      </div>
    </div>
    </div>


    <div class="short flex-c">
      <div class="poetry">
        <ul class="poetry-desc">
          <li
            v-for="(text, index) in poetry_list_1"
            data-aos="fade-up"
            :data-aos-delay="200 + (index + 1) * 200"
            data-aos-duration="1000"
            :key="text"
          >{{text}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.logo-img{
	.st0{fill:#00467C;}
	.st1{fill:#BC7046;}
	.st2{fill:none;stroke:none;stroke-miterlimit:10;}	
	.st3{clip-path:url(#SVGID_2_);}
  .ro{
    &1{animation:ball 10s ease-in infinite;transform-origin: 53.9px -26.8px;}
    &2{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 53.9px 77.5px;}
    &3{animation:ball 10s ease-in infinite;transform-origin: 53.9px 181.6px;}
    &4{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 158px -26.8px;}
    &5{animation:ball 10s ease-in infinite;transform-origin: 158px 77.5px;}
    &6{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 158px 181.6px;}
    &7{animation:ball 10s ease-in infinite;transform-origin: 275.4px -26.8px;}
    &8{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 275.4px 77.5px;}
    &9{animation:ball 10s ease-in infinite;transform-origin: 275.4px 181.6px;}
    &10{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 87.7% -26.8px;}
    &11{animation:ball 10s ease-in infinite;transform-origin: 87.7% 77.5px;}
    &12{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 87.7% 181.6px;}
    &13{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 53.9px 300.8px;}
    &14{animation:ball 10s ease-in infinite;transform-origin: 53.9px 405.1px;}
    &15{animation:ball 10s ease-in infinite;transform-origin: 158px 300.8px;}
    &16{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 158px 405.1px;}
    &17{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 275.4px 300.8px;}
    &18{animation:ball 10s ease-in infinite;transform-origin: 275.4px 405.1px;}
    &19{animation:ball 10s ease-in infinite;transform-origin: 379.5px 300.8px;}
    &20{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 379.5px 405.1px;}
    &21{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 53.9px 523.6px;}
    &22{animation:ball 10s ease-in infinite;transform-origin: 53.9px 627.9px;}
    &23{animation:ball 10s ease-in infinite;transform-origin: 158px 523.6px;}
    &24{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 158px 627.9px;}
    &25{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 275.4px 523.6px;}
    &26{animation:ball 10s ease-in infinite;transform-origin: 275.4px 627.9px;}
    &27{animation:ball 10s ease-in infinite;transform-origin: 379.5px 523.6px;}
    &28{animation:ball 10s -5s reverse ease-out infinite;transform-origin: 379.5px 627.9px;}
  }
  }
  .logo{
	.st2{fill:#00457C;animation: op .5s 6s forwards;opacity: 0;}
	.st3{fill:#BB6F46;animation: op .5s 6s forwards;opacity: 0;}
  .st4{fill:#000;animation: op .5s 6s forwards;opacity: 0;}}
  .logo1{
    .st0{clip-path:url(#logo12);}
    .st1,
    .st2,
    .st3,
    .st4,
    .st5{fill:none;stroke:#00457C;
    animation-name:letterDraw;animation-fill-mode:forwards;}

    .st1{stroke-width:6;stroke-miterlimit:10;}
    .st2{stroke-width:27;stroke-miterlimit:10;}
    .st3{stroke-width:12;stroke-miterlimit:10;}
    .st4{stroke-width:15;stroke-linecap:round;stroke-miterlimit:10;}
    .st5{stroke-width:25;stroke-miterlimit:10;}
  }
  .logo2{
    .st1{clip-path:url(#logo22);}
    .st2,
    .st3{fill:none;stroke:#00457C;
    animation-name:letterDraw;animation-fill-mode:forwards;}
    .st2{stroke-width:27;stroke-miterlimit:10;}
    .st3{stroke-width:14;stroke-miterlimit:10;}
  }
  

  .ld{
    &1{stroke-dasharray:55;stroke-dashoffset:55;animation-duration:.1s;animation-delay:3s;}
    &2{stroke-dasharray: 230;stroke-dashoffset:230;animation-duration:.4s;animation-delay:3.1s;}
    &3{stroke-dasharray:58;stroke-dashoffset:58;animation-duration:.1s;animation-delay:3.5s;}
    &4{stroke-dasharray:100;stroke-dashoffset: 100;animation-duration:.2s;animation-delay:3.6s;}
    &5{stroke-dasharray: 110;stroke-dashoffset: 110;animation-duration:.2s;animation-delay:3.8s;}
    &6{stroke-dasharray: 75;stroke-dashoffset: 75;animation-duration:.1s;animation-delay:4s;}
    &7{stroke-dasharray: 70;stroke-dashoffset: 70;animation-duration:.1s;animation-delay:4.1s;}
    &8{stroke-dasharray: 160;stroke-dashoffset: 160;animation-duration:.23s;animation-delay:4.2s;}
    &9{stroke-dasharray: 67;stroke-dashoffset: 67;animation-duration:.1s;animation-delay:4.42s;}
    &10{stroke-dasharray: 60;stroke-dashoffset:60;animation-duration:.1s;animation-delay:4.52s;}
    &11{stroke-dasharray: 70;stroke-dashoffset: 70;animation-duration:.1s;animation-delay:4.62s;}
    &12{stroke-dasharray: 210;stroke-dashoffset: 210;animation-duration:.37s;animation-delay:4.72s;}
    &13{stroke-dasharray: 60;stroke-dashoffset: 60;animation-duration:.1s;animation-delay:5.1s;}
    &14{stroke-dasharray:70;stroke-dashoffset:70;animation-duration:.1s;animation-delay:5.2s;}
    &15{stroke-dasharray: 60;stroke-dashoffset: 60;animation-duration:.1s;animation-delay:5.3s;}
    &16{stroke-dasharray: 60;stroke-dashoffset: 60;animation-duration:.1s;animation-delay:5.4s;}
    &17{stroke-dasharray: 240;stroke-dashoffset: 240;animation-duration:.4s;animation-delay:5.5s;}
    &18{stroke-dasharray: 90;stroke-dashoffset: 90;animation-duration:.15s;animation-delay:5.9s;}
  }

@keyframes ball{
50% {transform:rotate(360deg);}
	to {transform:rotate(360deg);}
}
@keyframes letterDraw{
    to {
        stroke-dashoffset: 0
    }
}
@keyframes op{
	to {opacity: 1;}
}

</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section1 {
  width: size(1920);
  height:100vh;
  background-image: url('./s1/bg.jpg');
  background-size: cover;
  background-position: center;
 // background-attachment: fixed;
}

.bg-img {
  width: 100vw;
  height: auto;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;

  &:nth-child(1) {
    position: relative;
  }
}

.logo {
  width: size(888);
  top:calc(50% - 8.1vw);
  right: size(245);
}
.logo1 {
  width: size(240);
  top:calc(50% - 8.3vw);
  left: size(775);
}
.logo2{
  width: size(240);
  top:calc(50% - 8.0vw);
  left: size(1000);
}

.logo_txt{
  top:calc(50% - 1.9vw);
  left: size(1252);
  font-size:size(30.5);
  font-weight: 700;
  letter-spacing: 0.02em;color: #000;
  span{color: #c1724b; margin: 0 0.5em 0 0;
  &.x{display:inline-block;width: 1.25em;height: 1em;position: relative; margin:0.1em 0 -0.1em 0;
  &::after,
  &::before{display:block;content:"";width: 100%;height: 0.05em;background: currentColor;position: absolute;top: 50%;left: 0;}
  &::after{transform: rotate(45deg);}
  &::before{transform: rotate(-45deg);}
  }
  }
}

.logo-img {
  width: size(428);
  top: calc(50% - 14.3vw);
  left: size(210);
}

.txt {
  width: size(886);
  top:calc(50% + 6.5vw);
  right: size(245);
}

.btn {
  width: size(335);
  height: size(80);
  color: #fff;
  font-size: size(30);
  background-color: #004980;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:calc(50% + 11.2vw);
  right: size(410);
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s;
  &:hover{
     background-color : #c1724b;
  }
}

.short {
  width: 100vw;
  height:size(800);
  margin: 0 0 size(280) 0;
}

.poetry {
  // position: absolute;
  width: size(287 + 68);
  // border-top: 0.5px solid #ccc;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  // padding-top: size(40);
  // padding-right: size(20);

  li {
 flex: 1;
  margin: 0 0 0 size(30);
  width: 1em;writing-mode: vertical-rl;text-align: left;
  display: flex; flex-direction:column;
  }
}

.poetry-desc {
  display: flex;
  flex-direction:row-reverse;
  width:100%;
 // writing-mode: vertical-rl;
 // text-orientation: upright;
  font-size: size(32);
  font-weight: normal;
  color: #004980;
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    height:calc(100vh - 63px);
    background-image: url('./mo/1/bg.jpg');
    background-size: cover;
    background-attachment: scroll;
  background-position: center;
  }

  .bg-img {
    width: 100vw;
    height: auto;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    object-fit: cover;

    &:nth-child(1) {
      position: relative;
    }
  }

  // .logo {
  //   width: size-m(241);
  //   top: size-m(88);
  //   right: size-m(67);
  // }

  .logo-img {
    width: size-m(112);
    top:calc(50% - 62vw);
    left: size-m(67);
  }
  .logo{
    width: size-m(241);
    top:calc(50% - 75vw);
    right: size-m(67);
    left: auto;
  }
.logo1 {
    width: size-m(95);
    top:calc(50% - 72.2vw);
    right: size-m(66);
    left: auto;
}
.logo2{
    width: size-m(95);
    top:calc(50% - 46vw);
    right: size-m(66);
    left: auto;
}

.logo_txt{
  top:calc(50% - 7vw);
  left: size-m(67);
  font-size:size-m(17);
}
  .txt {
    width: size-m(241);
    top:calc(50% + 3vw);
    right: size-m(67);
  }

  .btn {
    width: size-m(140);
    height: size-m(38);
    color: #fff;
    font-size: size-m(15);
    background: #004980;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:calc(50% + 43vw);
    right: size-m(168);
    cursor: pointer;
  }

  .short {
    width: 100vw;
    height: size-m(395);
    margin: 0 0 size-m(20) 0;
  }

  .poetry {
    width: size-m(200);

    li {
      flex: 1;
      margin:0 size-m(10);
    }
  }

  .poetry-desc {
    //width: size-m(190);
    width: 100%;
    font-size: size-m(19);
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      isMobile,
      isTablet,
      poetry_list_1: [
        '一條有故事的街道',
        '一棟有學問的房子',
        '職人手工限量十九席',
        '書墨浦城　靜邀',
        '拾藏百年城南歲月閑好',
      ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
